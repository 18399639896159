@import "/styles/tools/";

.featuredItems {
  $this: &;
  @include container;
  @include vertical-spacing;
  display: grid;
  gap: rem(40);

  &__heading {
    @include typeHeadline(24);
    color: token("secondary-on-general-light");

    @include above(md) {
      @include typeHeadline(32);
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;

    #{$this}.hasRelatedInsights & {
      @include above(md) {
        grid-template-columns: repeat(12, minmax(0, 1fr));
      }
    }
  }

  &__grid {
    display: grid;
    gap: rem(24);
    grid-template-columns: 1fr;

    @include above(md) {
      column-gap: rem(24);
      row-gap: rem(40);

      &.layout2wide {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      &.layout3wide {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      &.layout4wide {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      #{$this}.hasRelatedInsights & {
        grid-column: 1 / span 9;
      }
    }

  }

  &__item {
    @include rte(true, false);
    @include typeBody;

    &:not(.keyline_horizontal) {
      border-left: solid rem(2) token("general-feature-2");
      padding-left: rem(16);
      color: token("general-feature");

      @include above(md) {
        padding: {
          left: rem(40);
          right: rem(24);
        }
      }

      #{$this}__itemContent {
        @include above(md) {
          @include typeBody(20);
        }
      }

      #{$this}__itemHeadingWrapper {
        flex-direction: column;
        align-items: start;
        gap: rem(16);
        margin-bottom: rem(16);
      }

    }

    &.keyline_horizontal {
      border-top: solid rem(2) token("general-feature-2");
      padding-top: rem(16);
      color: token("secondary-on-general-light");
    }

  }

  &__itemHeadingWrapper {
    display: flex;
    align-items: center;
    margin-bottom: rem(16);
    gap: rem(12);

    [class*="_btn__external_"] {
      align-self: start;
      margin-top: rem(12);
      margin-right: rem(4);

      svg {
        transform: translateY(0);
      }
    }
  }

  &__itemContent {
    position: relative;
    @include rte(true, true);
  }

  &__itemHeading {
    @include typeHeadline(20);
    color: token("primary-on-general-light");

    @include above(md) {
      @include typeHeadline(24);

    }
  }

  &__icon {
    position: relative;
    display: block;
    width: rem(50);
    height: rem(50);

    img {
      width: rem(50);
      height: rem(50);
      object-fit: contain;
      object-position: center;
    }
  }

  &__richtext {
    position: relative;
  }

  &__itemImageWrapper {
    max-width: rem(160);
    height: auto;
    background-color: token("general-accent");
    @include aspect-ratio(160, 72);
    position: relative;
    margin-top: rem(-16);

    picture {
      position: absolute;
      inset: 0;
    }

    img {
      object-fit: contain;
      max-width: rem(100);
      max-height: rem(42);
      margin-top: auto !important;
      margin-bottom: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
      mix-blend-mode: darken;
    }
  }

  &__relatedInsights {
    @include above(md) {
      grid-column: span 3;
    }
  }
}