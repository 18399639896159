@import "/styles/tools/";

.mediaCard {
  $this: &;

  &__media {

    &:not([class*="no-aspect-ratio"]) {    
       @include aspect-ratio(15, 10);
      position: relative;
      width: 100%;
      overflow: hidden;
      border-radius: rem(4);
    
      @include above(md) {
        height: rem(418);
      }
    
      picture {
        position: absolute;
        inset: 0;
      }
    
      img {
        @include aspect-ratio(15, 10);
        width: 100%;
        height: auto;
      }      
    }

    &[class*="no-aspect-ratio"] {
      position: relative;
      width: 100%;      
      overflow: hidden;
      border-radius: rem(4);

      img {
        width: 100%;
        height: auto;
      }      
      
      #{$this}__image {
        position: relative !important;
        display: block;
        object-fit: unset;       
      }
    }
  }

  &__image {
    display: block;
    object-fit: cover;
  }

  &__playButton {
    @include reset-button;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: rem(64);
    height: rem(64);
    border-radius: 0 rem(8) 0 0;
    background-color: transparentize(token("interaction-on-general-dark"), 0.3);
    z-index: 1;
    transition: ease 0.2s;

    &:hover {
      background-color: token("interaction-on-general-dark");
    }

    @include above(md) {
      width: rem(96);
      height: rem(96);
    }
  }

  &__playIcon {
    width: rem(24);
    color: token("general-dark");

    @include above(lg) {
      width: rem(40);
    }
  }

  &__videoContainer {
    position: relative;
    aspect-ratio: 16/9;

        &.playing {
            #{$this}__playButton {
              opacity: 0;
              transition: opacity 0.5s ease;
            }
        
            &:hover {
              #{$this}__playButton {
                opacity: .8;
                transition: opacity 0.5s ease;
              }
            }
        
            &.sound {
              #{$this}__playButton {
                display: none;
              }
            }
          }
  }

  &__videoEl {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.gatedNoVideo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgba(map-get($colors, "error"), .15);
      box-shadow: inset 0 0 0 rem(2) rgba(map-get($colors, "error"), .5);
      display: flex;
      justify-content: center;
      align-items: center;
      color: map-get($colors, "error");
      text-align: center;
      line-height: 1.4;
      font-style: italic;
    }
  }

  &__loading {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  &__caption {
    @include typeLabel(12);
    position: relative;
    padding-left: rem(12);
    margin-top: rem(10);
    color: token("secondary-on-general-light");

    @include above("md") {
      @include typeLabel(16);
      margin-top: rem(8);
    }

    &:before {
      display: inline;
      position: absolute;
      width: rem(2);
      background-color: token("general-divide-feature-2");
      content: "";
      left: 0;
      height: 100%;
    }

    &.lightPurple,
    &.secondaryPurple {
      &:before {
        background-color: token("general-manage");
      }
    }

    &.lightYellow,
    &.secondaryYellow {
      &:before {
        background-color: token("general-perform");
      }
    }

    &.lightPink,
    &.secondaryPink {
      &:before {
        background-color: token("general-integrate");
      }
    }

    &.lightOrange,
    &.secondaryOrange {
      &:before {
        background-color: token("general-tbc");
      }
    }

    &.lightGreen,
    &.secondaryGreen {
      &:before {
        background-color: token("general-comply");
      }
    }
  }

  &__videoLength {
    margin-right: rem(8);
    font-weight: 600;
  }

  &__lightboxModalImage {
    position: relative;
  }

  &__lightbox {
    position: relative;

    &:hover,
    &:focus-visible {
      cursor: pointer;

      &::after {
        opacity: 1;
        transform: scale(1.05);
        transition: transform 0.25s ease-in-out;
      }
    }

    &:after {
      position: absolute;
      right: rem(8);
      bottom: rem(8);
      z-index: 1;
      transition: transform 0.25s ease-in-out;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.75 4.41602H9.83824V6.07691L6.59169 6.08062L11.3536 10.8425L10.1765 12.0196L5.4146 7.2577L5.41089 10.5043H3.75V4.41602Z' fill='%231C1B1F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.75 20.916H9.83824V19.2551L6.59169 19.2514L11.3536 14.4895L10.1765 13.3125L5.4146 18.0743L5.41089 14.8278H3.75V20.916Z' fill='%231C1B1F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.25 4.41602L20.25 10.5043L18.5891 10.5043L18.5854 7.2577L13.8235 12.0196L12.6464 10.8425L17.4083 6.08062L14.1618 6.07691L14.1618 4.41602L20.25 4.41602Z' fill='%231C1B1F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.25 20.916L20.25 14.8278L18.5891 14.8278L18.5854 18.0743L13.8235 13.3125L12.6464 14.4895L17.4083 19.2514L14.1618 19.2551L14.1618 20.916L20.25 20.916Z' fill='%231C1B1F'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-size: rem(16);
      background-repeat: no-repeat;
      background-color: token("interaction-on-general-dark");
      width: rem(24);
      height: rem(24);
      pointer-events: none;
      content: '';
      color: token("general-dark");
      
      @include above(md){
        right: rem(16);
        bottom: rem(16);
        width: rem(40);
        height: rem(40);
        background-size: auto;
      }
    }

  }

}

.modal {
  &__videoContainer {
    [class*="modal__content__"] {
      margin-right: auto !important;
      margin-left: auto !important;
      padding: 0 !important;
      max-width: 90% !important;
      height: auto !important;
      max-height: 90% !important;
    }
  }
}