@import "/styles/tools/";

.tagPill {
  @include typeLabel(14, "bold");
  font-size: rem(14);
  display: inline-block;
  padding: rem(2) rem(12);
  border-radius: rem(115);
  background-color: token("general-light");
  color: token("tertiary-on-general-light");

  &.navigationPill {
    @include typeDisplay(14, "bold");
  }

  &.generalAccent {
    background-color: token("general-accent");
    color: token("secondary-on-general-accent");
  }

  &.onDark {
    background-color: token("general-feature");
    color: token("primary-on-general-dark");
  }
}
