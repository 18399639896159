@import "/styles/tools";

.checkboxContainer {
  display: flex;
  align-items: center;

  @include below("md") {
    margin-bottom: rem(16);
  }

  &:focus-visible {
    outline: 2px solid color("legacy-blue");
  }
}

.checkboxInput {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  margin: 0;

  &:focus-visible {
    & + label {
      box-shadow: 0 0 0 rem(2) color("legacy-blue");

      &:before {
        box-shadow: 0 0 0 rem(2) token("interaction-primary") inset;
      }
    }
  }
}

.checkboxLabel {
  @include typeLabel(16);
  display: inline-flex;
  align-items: center;
  color: token("primary-on-general-light");

  &:hover {
    cursor: pointer;
    &:before {
      box-shadow: 0 0 0 rem(2) token("interaction-primary") inset;
    }
  }

  &:before {
    display: inline-block;
    width: rem(18);
    height: rem(18);
    margin-right: rem(16);
    border-radius: rem(2);
    box-shadow: 0 0 0 rem(2) token("secondary-on-general-accent") inset;
    content: "";
  }

  &.active {
    &:before {
      background-color: token("interaction-primary");
      background-image: url("data:image/svg+xml,%0A%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z' fill='%23070D19'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-size: rem(12) rem(10);
      background-repeat: no-repeat;
      box-shadow: 0 0 0 rem(2) token("interaction-primary") inset;
    }
  }
}
