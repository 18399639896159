@import "/styles/tools/";

.richTextBody {
  $this: &;

  &.standalone {
    @include vertical-spacing;
    @include container;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: rem(48);

    @include above(md) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
      gap: rem(24);
    }
  }

  &__inner {
    #{$this}.standalone & {
      @include above(md) {
        grid-column: span 8;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: rem(40);

    @include above(md) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-template-rows: 1fr;
      grid-column-gap: rem(24);
    }
  }

  &__main {
    #{$this}.standalone & {
      @include above(md) {
        grid-column: span 8;

        &.full {
          grid-column: span 12;
        }
      }
    }

    & > #{$this}__componentCta {
      margin-top: 0;
      margin-bottom: rem(32);
    }
  }

  &__aside {
    #{$this}.standalone & {
      @include above(md) {
        grid-column: 10 / -1;
      }
    }
  }

  &__title {
    @include typeHeadline(20);
    margin-bottom: rem(24);
    color: token("primary-on-general-light");
  }

  &__textBody {
    img {
      margin-right: rem(8);
    }
  }

  &__mediaCard {
    padding-top: rem(48);
    padding-bottom: rem(32);

    @include above("md") {
      padding-top: rem(56);
      padding-bottom: rem(32);
    }
  }

  &__componentCta {
    margin-top: rem(32);
  }
}
