@mixin fontSize($mobile, $tablet, $desktop) {
    font-size: rem($mobile);

    @include above("mdlg") {
        font-size: rem($tablet);
    }

    @include above("lg") {
        font-size: rem($desktop);
    }
}

@mixin lineHeight($mobile, $tablet, $desktop) {
    line-height: rem($mobile);

    @include above("mdlg") {
        line-height: rem($tablet);
    }

    @include above("lg") {
        line-height: rem($desktop);
    }
}

@mixin typeDisplay($size: 48, $weight: "book") {
    font-family: FinalSix, sans-serif;
    font-display: swap;
    letter-spacing: -0.02em;

    @if ($weight == "bold") {
        font-weight: 600;
    } @else if ($weight == "book") {
        font-weight: 300;
    } @else {
        font-weight: $weight;
    }

    @if ($size == 92) {
        @include fontSize(72, 92, 92);
        line-height: 88%;
        letter-spacing: -0.04em;
    } @else if ($size == 60) {
        @include fontSize(30, 48, 60);
        line-height: 130%;
        letter-spacing: -0.04em;
    } @else if ($size == 48) {
        @include fontSize(26, 32, 48);
        line-height: 130%;
        letter-spacing: -0.03em;
    } @else if ($size == 40) {
        @include fontSize(24, 40, 40);
        line-height: 130%;
        letter-spacing: -0.03em;
    } @else if ($size == 30) {
        @include fontSize(23, 30, 30);
        line-height: 140%;
        letter-spacing: -0.02em;
    } @else if ($size == 24) {
        @include fontSize(18, 24, 24);
        line-height: 150%;
        letter-spacing: -0.02em;
    } @else {
        line-height: 130%;
        letter-spacing: -0.04em;
    }
}

@mixin typeHeadline($size: 16, $weight: 400) {
    line-height: 150%;
    letter-spacing: -0.02em;

    @if ($weight == "bold") {
        font-weight: 600;
    } @else if ($weight == "book") {
        font-weight: 300;
    } @else {
        font-weight: $weight;
    }

    @if ($size == 16) {
        font-size: rem(16);
        letter-spacing: 0;
    } @else if ($size == 20) {
        font-size: rem(20);
        letter-spacing: -0.01em;
    } @else if ($size == 24) {
        font-size: rem(24);
    } @else if ($size == 28) {
        font-size: rem(28);
    } @else if($size == 32) {
        font-size: rem(32);
    } @else {
        font-size: rem($size);
    }
}

@mixin typeBody($size: 16, $weight: 400) {
    font-size: rem($size);
    line-height: 180%;
    letter-spacing: -0.02em;
    @if ($weight == "bold") {
        font-weight: 600;
    } @else if ($weight == "book") {
        font-weight: 300;
    } @else {
        font-weight: $weight;
    }

    @if ($size == 16) {
        letter-spacing: -0.01em;
    }
}

@mixin typeLabel($size: 12, $weight: 400) {
    line-height: 140%;
    font-size: rem($size);
    letter-spacing: 0;
    @if ($weight == "bold") {
        font-weight: 600;
    } @else if ($weight == "book") {
        font-weight: 300;
    } @else {
        font-weight: $weight;
    }

    @if ($size == 16) {
        letter-spacing: -0.01em;
    }
}
