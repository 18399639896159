@import "/styles/tools/";

.subNavItem {
  $block: &;

  &__subNavWrapper {
    position: absolute;
    background-color: token("general-light");

    @include below("md") {
      top: 0;
      left: 100%;
      width: 100vw;
    }

    @include above("md") {
      top: 101%;
      left: 0;
      right: 0;
    }

    &.closed {
      display: none;
    }
  }

  &__inner {
    @include below("md") {
      display: flex;
      flex-direction: column;
      padding: rem(40) rem(24) rem(120);

      @include above("md") {
        gap: rem(40);
      }
    }

    @include above("md") {
      @include container;
      padding: rem(40) 0 rem(60);
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      grid-template-rows: auto;
      row-gap: rem(40);
      column-gap: rem(48);
      grid-template-areas:
        "NavInfo NavOne NavTwo"
        "NavInfo NavOne NavThree";
    }

    @include above("lg") {
      column-gap: rem(98);
    }
  }

  &__NavInfo {
    grid-area: NavInfo;
    display: flex;
    flex-direction: column;
    margin-bottom: rem(40);

    @include above("md") {
      margin-bottom: 0;
    }
  }

  &__Nav0 {
    grid-area: NavOne;
    margin-bottom: rem(52);

    @include above("md") {
      margin-bottom: 0;
    }
  }

  &__Nav1 {
    grid-area: NavTwo;
    margin-bottom: rem(52);

    @include above("md") {
      margin-bottom: 0;
    }
  }

  &__Nav2 {
    grid-area: NavThree;
  }

  &__tag {
    width: fit-content;
    margin-bottom: rem(16);
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(16);
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__title {
    @include typeHeadline();
    font-weight: 600;
    font-size: rem(18);
    margin-bottom: rem(8);

    @include above("md") {
      margin-bottom: rem(16);
    }

    @include above("mdlg") {
      font-size: rem(20);
    }
  }

  &__text {
    @include typeHeadline(16);
    width: min(100%, rem(320));

    @include above("mdlg") {
      font-size: rem(20);
    }
  }

  &__cta {
    margin-top: rem(16);
  }

  &__subNavTitle {
    @include typeHeadline(20);
    color: token("primary-on-general-light");
    margin-bottom: rem(8);
  }

  &__subNavDescription {
    @include typeLabel(14);
    color: token("secondary-on-general-light");
    margin-bottom: rem(12);
  }

  &__subNavCta {
    margin-bottom: rem(32);
  }

  &__navLinks {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(20);

    @include above("md") {
      gap: rem(16);
    }

    a,
    button {
      @include typeLabel(16, bold);
      width: 100%;
      text-align: inherit;
      justify-content: space-between;
    }
  }
}