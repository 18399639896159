@import "/styles/tools/";

.componentInsights {
  $block: &;

  @include container;
  padding-left: 0;
  padding-right: 0;

  &__header {
    position: relative;
  }

  &__content {
    position: relative;
    isolation: isolate;
    padding-left: rem(16);
    padding-right: rem(16);
    margin-left: rem(-16);
    margin-right: rem(-16);
  }

  &__loading {
    display: flex;
    justify-content: center;
    inset: rem(0);
    padding-top: rem(150);
    z-index: 2;
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
  }

  &__contentInner {
    display: grid;
    gap: rem(16);
    padding-top: rem(40);
    padding-bottom: rem(40);
    position: relative;

    @include above("md") {
      gap: rem(24);
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include above("lg") {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    &.noResults {
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      align-items: center;
      padding-top: rem(20);
      padding-bottom: rem(40);
    }
  }

  &__noResults {
    display: flex;
    flex-direction: column;
    gap: rem(4);

    @include above("md") {
      flex-direction: row;
    }
  }

  &__zeroResultsText {
    @include typeHeadline(20);
    color: token("secondary-on-general-light");
  }

  &__searchText {
    @include typeHeadline(20, bold);
    color: token("primary-on-general-light");
  }

  &__insightsComponentContainer {
    [class*="multiCardCallout"] {
      border-top: 0;
    }

    [class*="multiCardCallout__inner"] {
      padding-top: rem(40);
      padding-bottom: rem(40);
    }

    [class*="calloutHero__container"] {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: rem(28);
    }

    & > [class^="ComponentCalloutHero_calloutHero"] {
      margin-bottom: rem(40);

      @include below("md") {
        max-width: 100% !important;
        padding-left: 0;
        padding-right: 0;
      }
    }

    + div {
      padding-top: rem(12);
    }

    &:empty {
      & + div {
        padding-top: rem(40);
      }
    }
  }
}

.insightsRef {
  position: absolute;
  top: rem(-100);
}
