@import "/styles/tools/";

.card {
  $this: &;
  $corner: rem(4);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: token("general-accent");
  border-radius: $corner;
  will-change: filter;
  transition: filter 0.2s ease-out;
  color: token("primary-on-general-accent");

  &.insightsLarge {
    background-color: token("general-dark");

    #{$this}__content {
      padding: rem(16) rem(20);

      @include above("md") {
        padding: rem(26) rem(24) rem(24);
      }
    }

    #{$this}__copy {
      display: none;
    }

    #{$this}__heading {
      @include above("md") {
        margin-bottom: rem(56);
      }
    }

    #{$this}__ctaWrapper {
      @include above("md") {
        margin-top: auto;
      }
    }
  }

  &:hover {
    filter: drop-shadow(#{rem(-2)} #{rem(4)} #{rem(8)} #{color("navy-11")});
  }

  &__top {
    position: relative;
  }

  &__image {
    @include aspect-ratio(3, 2);
    width: 100%;
    height: auto;
    border-radius: $corner $corner 0 0;
    position: relative;

    picture {
      position: absolute;
      inset: 0;
    }

    img {
      @include aspect-ratio(3, 2);
      width: 100%;
      height: auto;
    }

  }

  &__navTagWrapper {
    padding: {
      top: rem(16);
      left: rem(20);
    }
    margin-bottom: rem(16);

    @include above("md") {
      padding: {
        top: rem(24);
      }
    }

    #{$this}.hasImage & {
      position: absolute;
      bottom: 0;
      left: rem(24);
      padding: 0;
    }

    &.noImage {
      padding: {
        top: 0;
        left: 0;
      }
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: rem(16) rem(20);

    @include above("md") {
      padding: rem(24);
    }
  }

  &__tagWrapper {
    margin-bottom: rem(8);
  }

  &__tag {
    @include typeLabel(14, "bold");
    display: inline-flex;
    gap: rem(8);
    margin-right: rem(8);
    color: token("primary-on-general-accent");

    #{$this}.insightsLarge & {
      color: token("primary-on-general-dark");
    }
  }

  &__tagInner {
    color: token("secondary-on-general-accent");

    #{$this}.insightsLarge & {
      color: token("secondary-on-general-dark");
      @include typeLabel(14, "bold");
    }
  }

  &__contentUpper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(12);

    @include above("md") {
      margin-bottom: rem(18);
    }
  }

  &__heading {
    @include typeHeadline(20);

    @include above("md") {
      @include typeHeadline(24);
    }

    #{$this}.insightsLarge & {
      color: token("primary-on-general-dark");
      @include above("md") {
        @include typeHeadline(24);
      }
    }
  }

  &__headingLink {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  &__copy {
    @include rte(true, false);
    position: relative;
    margin-top: rem(8);
    margin-bottom: auto; // push cta wrapper to bottom

    #{$this}.isSecondary & {
      color: token("secondary-on-general-accent");
      margin-top: rem(12);

      @include above(md) {
        margin-top: rem(16);
      }
    }
  }

  &__contentTags {
    display: flex;
    gap: rem(8);
    margin-top: rem(12);

    @include above(md) {
      margin-top: rem(16);
    }
  }

  &__ctaWrapper {
    position: relative;
    margin-top: rem(24);
    border-top: solid rem(1) token("general-divide-light");
    padding-top: rem(12);

    #{$this}.insightsLarge & {
      border-top: solid rem(1) rgba(token("general-divide-dark"), 0.4);
    }
  }

  &__cta {
    @include typeLabel(14, bold);
    display: inline-flex;
    align-items: center;
    gap: rem(12);
    color: token("secondary-on-general-accent");

    svg {
      position: relative;
      margin-left: 0;
      transition: margin-left 0.2s ease-out;
    }

    #{$this}:hover & {
      svg {
        margin-left: rem(8);

        @include prefers-reduced-motion {
          margin-left: 0;
        }
      }
    }

    #{$this}.insightsLarge & {
      color: token("secondary-on-general-dark");
    }
  }
}
