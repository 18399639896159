@import 'styles/tools';

.footerNavigationList {
    flex: 1;

    &__heading > * {
        @include typeLabel(16);
        color: token("primary-on-general-dark");
        opacity: 0.6;

        &:hover {
            text-decoration: underline;
        }
    }

    &__items {
        list-style: none;
        margin-top: rem(16);
    }

    &__item {
        & + & {
            margin-top: rem(16);
        }
    }

    &__link {
        @include typeLabel(16, "bold");
        display: block;
        color: token("primary-on-general-dark");

        &:hover {
            text-decoration: underline;
        }
    }
}