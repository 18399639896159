@import "/styles/tools/";
.mobileMenu {
  $menu: &;

  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: map-get($z-index, "header");
  inset: 0;
  min-height: 100dvh;
  background-color: token("general-light");

  &__top {
    padding: rem(32) rem(24);
    border-bottom: rem(1) solid token("general-divide-light");
    display: flex;
    justify-content: center;

    &.dropdown_open {
      justify-content: flex-start;
    }

    &:not(.dropdown_open) {
      a,
      button {
        width: 100%;
        justify-content: center;
      }
    }
  }

  &__main {
    display: flex;
    width: 100%;
    position: relative;
    overflow-y: auto;
  }

  &__mainInner {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100% - 76px);
    min-height: 100vh;
    padding: rem(40) rem(24) rem(120);
    position: relative;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;

    @include below("lg") {      
      @media (orientation: landscape) {
        height: calc(100vh + 100px);
        min-height: calc(150vh + rem(100));
      }
    }
    

    &.dropdown-open {
      transform: translateX(-100vw);
      transition: transform 0.3s ease-in-out;
    }

    &.subDropdown-open {
      transform: translateX(-200vw);
      transition: transform 0.3s ease-in-out;
    }
  }

  &__contact {
    margin-top: rem(40);
  }

  &.closed {
    display: none;
  }

  &.open {
    display: flex;
  }
  
}
