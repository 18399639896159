@import "../media-queries/above";

%button {
  @include typeLabel(16, bold);
  display: inline-flex;
  align-items: center;
  width: fit-content;
  gap: rem(16);
  padding: rem(12) rem(24);
  border-radius: rem(60);
  cursor: pointer;
  opacity: 1;
  position: relative;
  box-shadow: 0 0 0 rem(3) transparent inset;
  transition: all 0.3s ease-in-out;
  border: 0;

  @include above("md") {
    padding: rem(16) rem(24);
  }

  &:hover {
    transition: all 0.3s ease-in-out;
  }

  &:focus-visible {
    outline: 0 !important;
    box-shadow: 0 0 0 rem(3) color("legacy-blue") inset !important;
    text-decoration: underline;
  }

  &:active {
    background-color: rgba(color("marine-green-digital"), 0.6);
    border-color: rgba(color("marine-green-digital"), 0.6);
  }

  &:disabled {
    opacity: 0.5;
  }
}

%button-primary {
  background: token("interaction-primary");
  color: token("primary-on-interaction-primary");
  outline: 0;
  box-shadow: 0 0 0 rem(1) color("marine-green-digital") inset;
}

%button-secondary {
  background: none;
  color: token("primary-on-interaction-primary");
  outline: 0;
  box-shadow: 0 0 0 rem(1) rgba(token("general-divide-dark"), 0.4) inset;

  &:hover {
    outline: 0;
    text-decoration: underline;
    box-shadow: 0 0 0 rem(2) token("interaction-primary") inset;
  }
}

%button-tertiary {
  display: inline-flex;
  width: fit-content;
  position: relative;
  border-radius: 0 !important;
  background: none;
  color: token("primary-on-interaction-primary");

  padding: {
    left: 0;
    right: 0;
    bottom: rem(12);
  }

  border: 0;
  border-bottom: rem(4) solid;
  border-bottom-color: token("underline-interaction-text-link");

  &:hover,
  &:focus-visible {
    border-bottom-color: token("interaction-primary");
  }

  &:active {
    background-color: transparent;
  }
}