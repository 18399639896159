@import "/styles/tools/";

.nav {
  $nav: &;

  width: 100%;

  &__list {
    display: flex;
    justify-content: flex-start;
    gap: rem(16);
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    list-style-type: none;
    isolation: isolate;

    @media screen and (min-width: rem(801)) and (max-width: rem(900)) {
      gap: rem(16);
    }

    @include above("lg") {
      gap: rem(24);
    }
    
    @media screen and (min-width: rem(1024)) {
      margin-left: rem(32);      
    }
    
    @media screen and (min-width: rem(1200)) {
      gap: rem(32);      
    }
    
  }

  &.mobile {
    #{$nav}__list {
      display: flex;
      flex-direction: column;
      gap: rem(24);
    }
  }
}
