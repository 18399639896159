@import "/styles/tools/";

.radioButtonContainer {
  display: flex;
  align-items: center;
}

.radioButtonInput {
  opacity: 0;

  &:focus-visible {
    & + label {
      box-shadow: 0 0 0 rem(2) color("legacy-blue");
      &:before {
        box-shadow: 0 0 0 rem(2) token("interaction-primary") inset;
      }
    }
  }
}

.radioButtonLabel {
  $block: &;

  @include typeLabel(16);
  display: inline-flex;
  align-items: center;
  color: token("primary-on-general-light");

  &:hover {
    cursor: pointer;
    &:before {
      box-shadow: 0 0 0 rem(2) token("interaction-primary") inset;
    }
  }

  &:before {
    display: inline-block;
    width: rem(18);
    height: rem(18);
    margin-left: rem(1);
    margin-right: rem(16);
    border-radius: 50%;
    box-shadow: 0 0 0 rem(2) token("secondary-on-general-accent") inset;
    content: "";
  }

  &.active {
    &:after {
      content: "";
      background-color: token("interaction-primary");
      background-position: center;
      background-size: rem(12) rem(10);
      background-repeat: no-repeat;
      box-shadow: 0 0 0 rem(2) token("interaction-primary") inset;
    }

    &#{$block} {
      &:before {
        width: rem(16);
        height: rem(16);
        margin-left: 2px;
        margin-right: rem(17);
        border: rem(2) solid white;
        background-color: token("interaction-primary");
        background-position: center;
        background-size: rem(12) rem(10);
        background-repeat: no-repeat;
        box-shadow: 0 0 0 rem(2) token("interaction-primary");
        content: "";
      }
    }
  }
}
