@mixin visibly-hidden {
    position: absolute;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    overflow: hidden;
    white-space: nowrap;
}
