@import "/styles/tools/";
@import "/styles/ui";

.languageSelector {
  $block: &;
  position: relative;
  z-index: 1;

  &__button {
    @include typeLabel(12, bold);
    display: flex;
    align-items: center;
    color: token("primary-on-general-feature");
    border: 0;
    padding: 0;
  }

  &__dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: rem(32);
    left: 0;
    right: 0;
    padding: rem(16);
    background-color: token("general-accent");
    border-radius: rem(4);
    box-shadow: 0 rem(4) rem(34) rgba(token("general-dark"), 0.16);
    width: min(100% - rem(32), 400px);

    @include above("md") {
      bottom: unset;
      top: rem(22);
      padding: rem(8);
      width: rem(145);
    }
  }

  &__setItem {
    @include typeLabel(12, bold);
    display: flex;
    align-items: center;
    border: 0;
    border-radius: rem(70);
    padding: rem(12);
    gap: rem(8);
    background-color: token("general-accent");
    color: token("primary-on-general-accent");
    margin-top: rem(24);
    cursor: pointer;
    
    &:before{
      display: block;
      width: rem(16);
      height: rem(16);
      background-image: map-get($icons, 'language-gb' );
      background-position: center;
      background-size: contain;      
      background-repeat: no-repeat;
      content: '';
    }
    
    &.fr {
      &:before {
        background-image: map-get($icons, 'language-fr' )
      }
    }
    
    @include above("md") {
      padding: 0;
      margin-top: 0;
      background-color: transparent;
      color: token("primary-on-general-feature");
    }
  }

  &__item {
    @include reset-button;
    @include typeLabel(14);
    display: inline-flex;
    align-items: center;
    gap: rem(8);
    width: rem(130);
    padding: rem(8);
    border-radius: rem(4);
    color: token("primary-on-general-accent");
    cursor: pointer;

    &:hover {
      background-color: token("general-light");
      text-decoration: underline;
    }
  }

  &__text {
    min-width: rem(45);
  }

  &__arrow {
    &:before {
      display: block;
      width: rem(8);
      height: rem(8);      
      background-image: map-get($icons, 'chevron-down' );
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(0deg);
      content: '';
      
      @include above("md") {
        background-image: map-get($icons, 'chevron-down-light' );
      }
    }
  }

  &.open {
    #{$block}__arrow {
      &:before {
        transform: rotate(180deg);
      }
    }
  }

  &__language {
    
    button {
      &:before {
        content: '';
        width: rem(16);
        height: rem(16);
        background-image: map-get($icons, 'language-gb' );
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;      
      }      
    }
      
    &.en {
      button {
        &:before {
          background-image: map-get($icons, 'language-gb' )
        }
      }
    }

    &.fr {
      button {
        &:before {
          background-image: map-get($icons, 'language-fr' )
        }
      }
    }

  }
}
