@import "/styles/tools/";

.cardListing {
  @include container;
  @include vertical-spacing;

  &__top {
    @include above(md) {
      padding-bottom: rem(24);
      border-bottom: solid rem(1) token("general-divide-light");
    }
  }

  &__heading {
    @include typeHeadline(24);
    color: token("secondary-on-general-light");

    @include above(md) {
      @include typeHeadline(32);
    }
  }

  &__intro {
    @include typeHeadline(16);
    margin-top: rem(8);
    color: token("primary-on-general-light");
        
    @include above(md) {
      @include typeHeadline(20);
      margin-top: rem(16);
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: rem(24);
    margin-top: rem(24);

    @include above(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__secondaryButton {
    @include above(md) {
      margin-left: auto;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(16);
    margin-top: rem(40);

    @include above(md) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: rem(24);
    }
  }
}