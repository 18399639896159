@import '/styles/tools/';

.breadcrumb {
    $block: &;

    &.dark {
        #{$block}__item {
            color: token("secondary-on-general-light");

            &:last-of-type {
                color: token("general-dark");
            }
        }
    }

    &__items {
        display: flex;
        flex-flow: wrap;
        list-style: none;
    }

    &__item {
        color: token("secondary-on-general-dark");

        @include below(sm) {
            display: none;
        }

        &:after {
            content: '/';
            display: inline-block;
            padding: 0 rem(8);
        }

        &:last-of-type {
            color: token("primary-on-general-dark");

            @include below(sm) {
                display: block;
            }

            &:after {
                display: none;
            }
        }

        &:nth-last-of-type(2) {
            @include below(sm) {
                display: block;
            }
        }
    }

    &__link {
        @include typeLabel(14);
        color: inherit;
    }
}