@import "/styles/tools/";

.mediaGallery {
  $block: &;

  @include vertical-spacing;
  border-bottom: rem(1) solid token("general-divide-light");
  overflow: hidden;

  &:global(.bg__white) {
    background-color: token("primary-on-general-dark");
  }

  &:global(.bg__light) {
    background-color: token("secondary-on-general-feature");
  }

  &:global(.bg__teal) {
    background-color: token("interaction-on-general-feature");
    border-bottom-color: token("interaction-on-general-feature");
        [class*="__caption__"] {
            color: token("primary-on-general-accent");
        
            &:before {
              background-color: token("primary-on-general-accent");
            }
          }
  }

  &:global(.bg__dark) {
    background-color: token("general-dark");
    border-bottom-color: token("general-dark");

    #{$block}__title, 
    #{$block}__body {
      color: token("primary-on-general-dark");
    }

    [class*="__caption__"] {
      color: token("primary-on-general-dark");
      &:before {
        background-color: token("general-divide-light");
      }
    }
  }

  &__title {
    @include typeDisplay(24, 400);
    margin-bottom: rem(16);
    font-size: rem(24);

    @include above(md){
      @include typeDisplay(40, 400);
      font-size: rem(40);
      margin-bottom: rem(24);
    }
  }

  &__body {
    @include typeBody(16);
    font-size: rem(16);
    text-wrap: pretty;

    @include above(md){
      @include typeBody(20);
      font-size: rem(20);
    }    
  }
  
  &__content {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: rem(24);
    margin-bottom: rem(40);
    
    @include above("md") {      
      margin-bottom: rem(64);
    }

    &Inner {
      grid-column: span 12;

      @include above("md") {
        grid-column: span 8;
      }
    }
  }

  &__container {
    @include container;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(32);

    @include above("md") {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: rem(24);

      &.singleItem {
        grid-template-columns: repeat(1, minmax(0, 65%));
      }
    }
  }



  // Overrides
  [class*="_mediaCard__media__"] {
    @include aspect-ratio(3, 2);

    @include above(md) {
      height: auto !important;
      max-height: rem(525);
      max-width: rem(788);
    }

    @include below(md) {
      height: auto !important;
    }


  }

  [class*="_mediaCard__playButton__"] {
    @include below(lg){
      width: 64px !important;
      height: 64px !important;
      border-top-right-radius: 4px;
    }
    
    @include below(sm){
      width: 40px !important;
      height: 40px !important;
      border-top-right-radius: 4px;
    }
  }

  [class*="_mediaGallery__item__"] {
    height: auto !important;
  }  

  :global(.keen-slider__slide) {
    min-height: auto;
  }

  // Carousel Control Overrides
  [class^="Carousel_carousel__button__"] {
    background-color: token("interaction-primary");
    color: token("primary-on-interaction-primary");
    border:0;

    &:hover,
    &:focus-visible {      
      background-color: token("general-feature") !important;
      color: token("primary-on-general-feature") !important;
    }

    @include below("md") {
      display: none;
    }
  }

  [class^="Carousel_carousel__controlList"] {
    padding-left: rem(24) !important;
    padding-right: rem(24) !important;
  }

  // Component Overrides
  & + [class^="ComponentInsightListing_insightCardListing"] {
    margin-top: rem(0) !important;
    border-top: 0 !important;
  }

  & + [class^="ComponentMultiCardCallout_multiCardCallout"] {
    border-top: 0 !important;
  }
}

.mediaGallery__carousel {
  position: relative;
  width: 100%;
  padding:0;
  
  :global(.keen-slider) {
    width: 100%;
    overflow: visible;
  }

  :global(.keen-slider__slide:not(.in-view)) {
    opacity: 0.5;
  }

  .mediaGallery__item {
    width: 100%;
    height: 100%;
  }
}
