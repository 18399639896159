@import "/styles/tools/";

.footer {
    $block: &;
    padding: rem(40) 0 rem(80);
    background: token("general-dark");
    color: token("primary-on-general-dark");

    @include above("md") {
        padding: rem(80) 0 0;
    }

    &__container {
        @include container;
        position: relative;

        @include above("md") {
            & + & {
                margin-top: rem(80);

                &::before {
                    content: "";
                    position: absolute;
                    width: 150vw;
                    height: 1px;
                    left: -50vw;
                    top: 0;
                    background-color: token("general-divide-dark");
                }
            }
        }
    }

    &__grid {
        @include above("md") {
            display: grid;
            gap: rem(128);
            grid-template-columns: repeat(2, 1fr);

            &.noGap {
                gap: 0;
            }
        }
    }

    &__column {
        &.withPadding {
            display: flex;
            padding-top: rem(40);

            @include above("md") {
                padding: rem(40) 0;
                & + & {
                    border-left: 1px solid token("general-divide-dark");
                }
            }
        }
    }

    &__brand {
        display: flex;
        flex-flow: column;
        gap: rem(40);

        @include above("md") {
            padding-right: rem(64);
        }

        @include above("lg") {
            flex-flow: row;
            align-items: center;
            gap: rem(48);
        }

        img {
            max-width:100%;
            height: auto;
        }
    }

    &__legal {
        display: flex;
        flex-flow: column;
        gap: rem(40);

        @include above("md") {
            padding-left: rem(64);
            gap: rem(16);
        }
    }

    &__calloutHeading {
        @include typeHeadline(28);

        @include above("md") {
            @include typeHeadline(32);
        }
    }

    &__calloutCta {
        margin-top: rem(40);

        > a,
        > button {
            @include below("md") {
                gap: rem(124) !important;
            }
        }
    }

    &__navigationContainer {
        display: flex;
        flex-flow: column;
        gap: rem(40);

        @include below("md") {
            margin-top: rem(40);
        }

        @include above("md") {
            flex-flow: row;
            gap: rem(16);
        }
    }
}
