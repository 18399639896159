@import "/styles/tools/";
@import "/styles/ui/";

.filterPagination {
  $block: &;

  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-items: center;
  margin-bottom: rem(25);
  padding-top: rem(25);
  border-top: rem(1) solid token("general-divide-light");
  @include above("md") {
    margin-bottom: rem(64);
    padding-top: rem(48);
  }

  &__results {
    @include typeLabel(16);
    position: absolute;
    top: rem(54);
    left: 0;
    display: flex;
    justify-content: flex-start;
    color: token("primary-on-general-light");

    &.no_pagination {
      position: relative;
      top: unset;
    }

    @include below("md") {
      display: none;
    }
  }

  &__paginationContainer {
    display: flex;
    justify-content: center;
  }

  &__page {
    display: flex;
    justify-content: flex-end;
  }
}

.reactPagination {
  display: inline-flex;
  align-items: center;
  gap: rem(4);

  @include above("md") {
    gap: rem(8);
  }
}

.paginationItemPrev,
.paginationItemNext,
.paginationItemNumber {
  a {
    @include typeLabel(14);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 50%;
    color: token("primary-on-general-light");
    cursor: pointer;
  }

  &:hover,
  &:focus-visible,
  &.paginationActive {
    a {
      box-shadow: 0 0 0 rem(2) token("interaction-primary") inset;
    }
  }
}

.paginationItemNumber {
  a {
    width: rem(28);
    height: rem(28);

    @include above("md") {
      width: rem(32);
      height: rem(32);
      box-shadow: 0 0 0 rem(1) token("general-divide-light") inset;
    }
  }
}

.paginationItemPrev,
.paginationItemNext {
  a {
    width: rem(32);
    height: rem(32);
    font-size: 0;
    background-position: center;
    background-size: rem(6);
    background-repeat: no-repeat;
    box-shadow: 0 0 0 rem(1) token("primary-on-general-light") inset;
  }

  &[class*="disabled"] {
    pointer-events: none;
    color: token("general-divide-light");

    a {
      box-shadow: 0 0 0 rem(1) token("general-divide-light") inset;
    }
  }
}

.paginationItemNext {
  a {
    background-image: map-get($icons, "pagination-right-dark");
    background-position: rem(14) center;
  }

  &[class*="disabled"] {
    a {
      background-image: map-get($icons, "pagination-right-light");
    }
  }
}

.paginationItemPrev {
  a {
    background-image: map-get($icons, "pagination-left-dark");
    background-position: rem(12) center;
  }

  &[class*="disabled"] {
    a {
      background-image: map-get($icons, "pagination-left-light");
    }
  }
}

.paginationEllipsis {
  display: flex;
  align-items: center;
  width: rem(20);
  height: rem(28);
  background-image: map-get($icons, "pagination-ellipsis");
  background-repeat: no-repeat;
  background-position: center;
  background-size: rem(19);
  pointer-events: none;

  @include above("md") {
    width: rem(32);
    height: rem(32);
  }

  a {
    display: none;
  }
}
