@import "/styles/tools/";

.pillar {
    display: block;
    border-left: rem(2);
    border-style: solid;
    padding-left: rem(16);

    &__heading {
        @include typeHeadline($weight: 600);
        color: token("general-dark");
    }
}