@import "/styles/tools/";

.pagination {
  position: relative;
  border-top: 1px solid token("general-divide-light");
  display: flex;
  gap: rem(8);
  width: 100%;
  justify-content: center;
  align-items: center;
  height: rem(120);

  &__summary {
    display: none;
    @include above("md") {
      display: block;
    }
    position: absolute;
    left: 0;
  }

  &__item {
    border: 1px solid token("general-divide-light");
    border-radius: 100%;
    width: rem(32);
    height: rem(32);
    display: flex;
    justify-content: center;
    align-items: center;

    &__active,
    &:hover {
      border: 2px solid token("interaction-on-general-dark");
      cursor: pointer;
    }

    svg {
      color: inherit;
      path {
        stroke-width: 1px !important;
      }
    }

    &.disabled {
      pointer-events: none;
      color: #dbdcdf;
      svg {
        color: inherit;
        path {
          stroke-width: 1px !important;
        }
      }
    }
  }
}
