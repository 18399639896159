@import '/styles/tools/';

.footerLegal {
    &__text,
    &__copyright {
        @include typeLabel(14);
        color: token("secondary-on-general-dark");
        text-wrap: balance;
    }

    &__copyright {
        margin-left: .5ch;
    }

    &__items {
        display: flex;
        margin-top: rem(40);
        gap: rem(16);
        list-style: none;
        flex-flow: row wrap;

        @include above("md") {
            margin-top: rem(16);
        }
    }

    &__link {
        @include typeLabel(14, 'bold');
        color: token("secondary-on-general-dark");
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}