@import "/styles/tools/";

.marketoForm {
    $block: &;
    width: 100% !important;

    &__loading {
        text-align: center;
    }

    :global(.mktoFormRow) {
        & + & {
            margin-top: rem(24);
        }
    }

    :global(.mktoOffset),
    :global(.mktoGutter) {
        display: none;
    }

    :global(.mktoFieldWrap) {
        display: flex;
        flex-flow: column;
    }

    :global(.mktoFieldWrap),
    :global(.mktoFormCol) {
        float: none !important;
    }

    // Label
    :global(label) {
        @include typeLabel(16, "bold");
        display: block;
        width: auto!important;
        color: token("general-dark");
    }

    // Asterix
    :global(.mktoAsterix) {
        @include typeLabel(16, "bold");
        color: token("interaction-on-general-dark") !important;
    }

    // Input, select
    :global(.mktoField) {
        width: 100%!important;
        margin-top: rem(4) !important;
        border: 1px solid token("general-divide-light") !important;
        line-height: rem(19) !important;
        height: auto !important;
        border-radius: rem(4) !important;
        padding: rem(13) rem(16) !important;
    }

    // Field wrapper
    :global(.mktoFieldWrap) {
        display: flex;
        flex-flow: row wrap;
    }

    // Checkbox
    :global(.mktoCheckboxList) {
        float: none!important;
        width: auto!important;
        padding: 0!important;
        order: -1;
        margin-right: rem(16);

        input {
            width: rem(20);
            height: rem(20);
            float: none!important;
            accent-color: token("interaction-on-general-dark") !important;
            border: 1px solid token("general-divide-light") !important;
            cursor: pointer!important;

            &:hover {
                accent-color: #00c5b7!important;
            }
        }

        label {
            min-height: 0!important;
        }
    }

    // Button
    :global(.mktoButtonWrap) {
        margin-top: rem(40);
        margin-left: 0!important;
    }

    :global(.mktoButton) {
        @include typeLabel(16, bold);
        margin-top: rem(40)!important;
        padding: rem(12) rem(24) !important;
        border-radius: rem(60) !important;
        cursor: pointer !important;
        opacity: 1 !important;
        position: relative !important;
        transition: all 0.3s ease-in-out;
        border: 0 !important;
        background: token("interaction-primary") !important;
        color: token("primary-on-interaction-primary") !important;
        outline: 0 !important;
        box-shadow: 0 0 0 rem(1) color("marine-green-digital") inset !important;
    }

    &__success {
        @include typeHeadline(20);
        margin-top: rem(16);
        color: inherit;
    }
}
