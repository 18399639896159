@import "/styles/tools/";

.stickyMenu {
  $block: &;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  border-top: rem(1) solid token("general-divide-light");

  &:not(&[class*="sticky-menu--active"]) {
    #{$block}__right {
      display: none;
    }
  }

  &__right {
    @include below(md){
      display: none;
    }
  }

  &[class*="sticky-menu--active"] {
    position: fixed;
    border-bottom: rem(1) solid token("general-divide-light");
    z-index: 10;
  }

  &__container {
    @include container;
    @include below(md){
      padding-right:0 !important;
    }
  }

  &__inner {
    position: relative;
    width:100%;
    margin-inline: auto;
    height: rem(72);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::-webkit-scrollbar { 
      display: none;
    }

    @include below("md") {
      height: rem(56);
      padding-right: rem(16);
      overflow-x: auto;
      white-space: nowrap;
      overflow-y: clip;
    }
  }

  &__left {
    position: relative;
  }

  &__cta {

    button {
      padding: rem(12) rem(18) !important;

      &:hover {
        &[class*="_arrow"]{
          &[class*="icon_right"]{
            svg {
              transform: translateX(.5rem) !important;
            }
          }
        }
      }
    }
  }

  &__navigation {
    display: flex;
    gap: rem(32);

    a {
      @include typeLabel(14, bold);
      position: relative;
      display: inline-flex;
      width: fit-content;
      border-radius: 0 !important;
      background: none;
      color: token("secondary-on-general-light");
      border: 0;
      padding: 0;
      gap: 0;

      @include above("mdlg") {
        font-size: rem(15);
      }

      @include above("lg") {
        font-size: rem(16);
      }

      &:before {
        position: absolute;
        display: flex;
        content: "";
        height: rem(4);
        background-color: transparent;
        bottom: rem(-25);
        left: 0;
        right: 0;

        @include below("md") {          
          bottom: rem(-18);
        }
      }

      &:is(&:hover),
      &:is(&:focus-visible) {
        color: token("primary-on-general-light");

        &:before {
          background-color: token("secondary-on-general-light");
        }
      }

      &:is(.active),
      &:is(&[class*="active"]),
      &:is(&:active) {
        color: token("primary-on-general-light");
        background-color: transparent !important;

        &:before {
          background-color: token("interaction-primary");
        }
      }
    }
  }


}