@import "/styles/tools";
@import "/styles/ui";

.insightsFilters {
  $block: &;

  background-color: token("general-light");

  &__innerTop {
    padding-top: rem(24);
    padding-bottom: rem(24);
    border-bottom: rem(1) solid token("general-divide-light");

    @include above("md") {
      padding-top: rem(40);
      padding-bottom: rem(40);
    }
  }

  &__topFilters {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;

    @include above("md") {
      grid-template-columns: minmax(0, 5fr) minmax(0, 2fr);
    }
  }

  &__topTopics {
    width: min(100%, rem(995));
    margin-top: rem(24);
    background-color: token("general-accent");

    @include above("md") {
      display: grid;
      gap: rem(24);
      grid-template-columns: minmax(0, 1fr) auto;
      margin-left: auto;
      padding: rem(24);
    }
  }

  &__topics {
    display: flex;
    align-items: center;
    gap: rem(16);

    @include above("md") {
      justify-content: flex-end;
    }
  }

  &__components {
    display: flex;
    flex-direction: row;
    gap: rem(8);
  }

  &__filterBoxes {
    display: grid;
    gap: rem(14);

    @include above("md") {
      grid-template-columns: repeat(auto-fit, minmax(rem(150), 1fr));
    }

    &.no_filters {
      align-items: center;
    }
  }

  &__filterTags {
    display: flex;
    flex-direction: column;
    gap: rem(16);

    @include above("md") {
      flex-direction: row;
      gap: rem(8);
    }

    @media (min-width: 769px) and (max-width: 811px) {
      gap: rem(6);

      button {
        font-size: rem(12);
      }
    }

    @media (min-width: 812px) and (max-width: 945px) {
      gap: rem(8);

      button {
        font-size: rem(13);
      }
    }
  }

  &__topicHeading {
    @include typeHeadline(16, bold);
    color: token("secondary-on-general-light");
  }

  &__topicButton {
    @include typeHeadline(16);
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(8);
    width: 100%;
    margin-right: rem(8);
    padding: rem(8) 0;
    border: 0;
    border-top: rem(1) solid token("general-divide-light");
    border-bottom: rem(1) solid token("general-divide-light");
    color: token("primary-on-general-light");
    cursor: pointer;

    @include above("md") {
      justify-content: center;
      width: auto;
      padding: rem(8) rem(16);
      border: 0;
      border-radius: rem(80);
      box-shadow: 0 0 0 rem(1) token("general-divide-light") inset;
    }

    &:after {
      content: "";
      width: rem(14);
      height: rem(14);
      background-image: map-get($map: $icons, $key: "chevron-down");
      background-position: center;
      background-size: rem(8);
      background-repeat: no-repeat;
    }

    &:hover {
      box-shadow: 0 0 0 rem(1) color("marine-green-digital") inset;
    }

    &.active,
    &:active {
      box-shadow: 0 0 0 rem(1) color("marine-green-digital") inset;

      &:after {
        background-image: map-get($map: $icons, $key: "chevron-down-darker");
        transform: rotate(180deg);
      }
    }
  }

  &__topicCount {
    @include typeLabel(14, bold);
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(22);
    height: rem(22);
    border-radius: 50%;
    background: token("interaction-primary");
    color: token("primary-on-interaction-primary");
    outline: 0;
  }

  &__appliedFiltersWrapper {
    display: flex;
    flex-direction: column;
    gap: rem(12);
    padding: rem(8) 0;

    @include above("md") {
      flex-direction: row;
      align-items: center;
      padding: rem(40) 0;
    }
  }

  &__appliedFiltersHeading {
    @include typeHeadline(16);
    color: token("secondary-on-general-light");
  }

  &__appliedFilters {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: rem(16);

    @include above("md") {
      flex-direction: row;
      align-items: center;
      gap: rem(12);
    }
  }

  &__filterHeading {
    @include typeHeadline(20);
    margin-bottom: rem(8);
    font-size: rem(20);
    color: token("secondary-on-general-light");
  }

  &__filterButton {
    @include typeHeadline(16, bold);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: rem(15.33);
    width: 100%;
    margin-right: rem(8);
    padding: rem(12) rem(16);
    color: token("primary-on-interaction-primary");
    border: 0;
    border-radius: rem(80);
    box-shadow: 0 0 0 rem(1) token("general-divide-light") inset;

    &:before {
      content: "";
      width: rem(16);
      height: rem(16);
      background-image: map-get($map: $icons, $key: "filter");
      background-position: center;
      background-size: rem(16);
      background-repeat: no-repeat;
    }

    &.active,
    &:active {
      box-shadow: 0 0 0 rem(1) color("marine-green-digital") inset;
    }
  }

  &__mobileFilters {
    position: fixed;
    z-index: map-get($z-index, "header");
    inset: 0;
    background-color: token("general-light");
    overflow: auto;
  }

  &__mobileFiltersInner {
    position: relative;
    display: flex;
    width: 100%;
  }

  &__mobileFiltersTop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: map-get($z-index, "header");
    padding: rem(24);
    border-bottom: rem(1) solid token("general-divide-light");
    background-color: token("general-light");
  }

  &__mobileFiltersBottom {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100% - rem(76));
    margin-top: rem(95);
    padding: rem(24) rem(16) rem(40);
    overflow-y: auto;

    @include below("md") {
      @media (orientation: landscape) {
        height: calc(100vh + rem(76));
      }
    }
  }

  &__mobilefilterApply {
    margin-bottom: rem(24);
  }

  &__mobileFilterBy {
    @include typeHeadline(16, bold);
    margin-bottom: rem(8);
    color: token("interaction-on-general-light");
  }

  &__mobileFiltersContentTypes {
    display: grid;
    grid-template-rows: 0fr;
    border-bottom: rem(1) solid token("general-divide-light");
    transition: grid-template-rows 0.3s ease-in-out;

    &.active {
      #{$block}__mobileFiltersContentTypesList {
        grid-template-rows: 1fr;
        transition: grid-template-rows 0.3s ease-in-out;
      }

      #{$block}__listHeading {
        &:after {
          background-image: url("data:image/svg+xml,%0A%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6.29508L10.59 7.70508L6 3.12508L1.41 7.70508L-6.16331e-08 6.29508L6 0.295078L12 6.29508Z' fill='%23070D19'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }

  &__mobileFiltersContentTypesList {
    display: inherit;
    grid-template-rows: 0;
    overflow: hidden;
  }

  &__mobileFiltersTopics {
    display: grid;
    grid-template-rows: 0fr;
    border-bottom: rem(1) solid token("general-divide-light");
    transition: grid-template-rows 0.3s ease-in-out;

    &.active {
      #{$block}__mobileFiltersTopicsList {
        grid-template-rows: 1fr;
        transition: grid-template-rows 0.3s ease-in-out;
      }

      #{$block}__listHeading {
        &:after {
          background-image: url("data:image/svg+xml,%0A%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6.29508L10.59 7.70508L6 3.12508L1.41 7.70508L-6.16331e-08 6.29508L6 0.295078L12 6.29508Z' fill='%23070D19'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }

  &__mobileFiltersTopicsList {
    display: inherit;
    grid-template-rows: 0;
    overflow: hidden;
  }

  &__mobileListInner {
    padding: rem(4) 0 rem(20);
  }

  &__listHeading {
    @include typeHeadline(20);
    position: relative;
    display: flex;
    align-items: center;
    gap: rem(8);
    padding: rem(16) 0;
    color: token("secondary-on-general-light");

    &:after {
      position: absolute;
      right: 0;
      width: rem(12);
      height: rem(12);
      background-image: url("data:image/svg+xml,%0A%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1.70492L10.59 0.294922L6 4.87492L1.41 0.294921L-6.16331e-08 1.70492L6 7.70492L12 1.70492Z' fill='%23070D19'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
    }
  }
}
