@import "/styles/tools";

.featuredVideo {
  $block: &;

  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: cover;
    }

    &.expanded {
      #{$block}__preview {
        animation: fadeOut 1s ease-in-out forwards;
      }

      #{$block}__main {
        visibility: visible;
        animation: fadeIn 1s ease-in-out forwards;
      }
    }

  }

  &__preview,
  &__main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__preview {
    z-index: 2;
    animation: fadeIn 1s ease-in-out forwards;
  }

  &__main {
    visibility: hidden;
    z-index: 2;
  }

}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}