@mixin vertical-spacing($size: "md") {
  padding: {
    top: rem(40);
    bottom: rem(40);
  }

  @include above(md) {
    @if ($size=="md") {
      padding: {
        top: rem(64);
        bottom: rem(64);
      }
    }

    @else if ($size=="lg") {
      padding: {
        top: rem(80);
        bottom: rem(80);
      }
    }
  }
}