@import "styles/tools";

.socialLinks {
  $this: &;

  display: flex;
  flex-flow: row;
  gap: rem(40);

  @include above("md") {
    gap: rem(24);
  }

  &__link {
    display: block;
    color: token("interaction-on-general-dark");
    transition: ease-in-out 0.25s transform;

    &:hover {
      transform: scale(1.05);
    }
  }

  &.darkIcon {
    #{$this}__link {
      color: token("interaction-on-general-light");
      transition: ease-in-out 0.25s transform;

      &:hover {
        color: token("interaction-on-general-dark");
        transform: scale(1.05);
      }
    }
  }

  &.smaller {
    position: relative;
    top: rem(-3);
    gap: rem(8);

    @include above("md") {
      gap: rem(12);
    }

    #{$this}__link {
      width: rem(40);
      height: rem(40);

      svg {
        width: 100%;
        aspect-ratio: 1/1;
      }

      @include above("md") {
        width: rem(44);
        height: rem(44);
      }
    }
  }
}
