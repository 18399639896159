@import "/styles/tools/";

.marketoBlock {
  $block: &;
  @include vertical-spacing($size: "lg");
  position: relative;
  overflow: hidden;

  &.newsletterSignup {
    background-color: token("general-feature");
    color: token("primary-on-general-feature");
  }

  &.enquiry {
    background-color: token("general-accent");
    color: token("general-dark");

    #{$block}__label {
      color: token("secondary-on-general-light");
    }

    #{$block}__input,
    #{$block}__select {
      border-color: token("general-divide-light");
      color: token("general-dark");

      &::placeholder {
        color: token("general-dark");
      }
    }
  }

  &__inner {
    @include container;
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(32);

    @include above("md") {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: rem(134);
    }
  }

  &__heading {
    @include typeDisplay(40);
  }

  &__body {
    @include typeBody(16);
    margin-top: rem(16);
    color: token("secondary-on-general-light");

    @include above(md) {
      @include typeBody(20);
      margin-top: rem(24);
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__formSection {
    margin-bottom: rem(32);

    @include above("md") {
      margin-bottom: rem(40);
    }
  }

  &__label {
    @include typeLabel(16, "bold");
    display: block;
  }

  &__input {
    @include reset-input($usePixelRatio: false);
    @include typeHeadline(20);
    padding: rem(8) 0;
    border-bottom: solid rem(1) token("primary-on-general-feature");

    @include above("md") {
      @include typeHeadline(24);
    }

    &::placeholder {
      color: transparentize(token("primary-on-general-feature"), 0.1);
    }
  }

  &__selectContainer {
    position: relative;
  }

  &__selectArrow {
    position: absolute;
    top: 50%;
    right: 1rem;
    width: rem(19);
    height: rem(19);
    transform: translateY(-50%);
    color: token("interaction-on-general-light");
    pointer-events: none;
  }

  &__select {
    @include reset-select;
    @include typeHeadline(20);
    padding: rem(8) 0;
    border-bottom: solid rem(1) token("primary-on-general-feature");
    appearance: none;

    @include above("md") {
      @include typeHeadline(24);
    }

    &::placeholder {
      color: transparentize(token("primary-on-general-feature"), 0.1);
    }
  }

  &__input + &__label,
  &__selectContainer + &__label,
  &__select + &__label {
    margin-top: rem(32);

    @include above(md) {
      margin-top: rem(40);
    }
  }

  &__iconBg {
    position: absolute;
    width: rem(215);
    height: rem(215);
    right: rem(-46);
    bottom: rem(-115);
    opacity: 0.05;

    @include above("md") {
      width: rem(240);
      height: rem(240);
      right: rem(-42);
      bottom: rem(-106);
    }
  }
}