@import "/styles/tools/";

.pillars {
    display: flex;
    flex-flow: column;
    list-style: none;
    gap: rem(32);

    &__item {
        display: block;
    }
}