@import "/styles/tools/";
@import "/styles/ui/";

.shareThis {
  $block: &;

  &__wrapper {
    margin-bottom: rem(60);

    @include above("md") {
      margin-bottom: rem(64);
    }
  }

  &__title {
    @include typeHeadline(20);
    margin-bottom: rem(24);
    color: token("primary-on-general-light");

    &:after {
      content: "";
      display: inline-block;
      width: rem(16);
      height: rem(16);
      margin-left: rem(8);
      background-image: map-get($icons, "share_icon");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transform: translateY(rem(3));
    }
  }

  &__buttons {
    position: relative;
    display: flex !important;
    flex-wrap: wrap;
    gap: rem(8) !important;

    > [class^="st-btn"] {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      width: rem(48) !important;
      min-width: rem(48) !important;
      margin-right: unset !important;
      height: rem(48) !important;
      border-radius: 50% !important;
      border: rem(1) solid token("outline-interaction-secondary") !important;
      background-color: token("general-light") !important;
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: center;

      > img {
        display: none !important;
      }

      &[data-network="facebook"] {
        background-image: map-get($icons, "share_facebook");
      }

      &[data-network="twitter"] {
        background-image: map-get($icons, "share_twitter");
      }

      &[data-network="linkedin"] {
        background-image: map-get($icons, "share_linkedin");
      }

      &[data-network="email"] {
        background-image: map-get($icons, "share_email");
      }
    }

    [class^="st-label"] {
      display: none !important;
    }
  }
}
