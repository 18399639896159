@import "/styles/tools/";

.multiCardCallout {
  $this: &;
  position: relative;

  border: {
    top: solid rem(1) token("general-divide-light");
    bottom: solid rem(1) token("general-divide-light");
  }

  &__inner {
    @include container;
    @include vertical-spacing;

    &.insights {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__heading {
    @include typeHeadline(24);
    margin-bottom: rem(40);
    color: token("secondary-on-general-light");

    @include above("md") {
      @include typeHeadline(32);
    }
  }

  &__list {
    display: grid;
    gap: rem(16);

    @include above("md") {
      gap: rem(24);

      &.rowOfThree {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      &.rowOfFour {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    @include above("lg") {
      &.rowOfFour {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
  }

  &__item {
    list-style: none;
  }

  & + [class^="ComponentInsightListing_insightCardListing__"] {
    margin-top: rem(0) !important;
    border-top: 0 !important;
    border-bottom: rem(1) solid token("general-divide-light");
  }
}
