@import "/styles/tools/";

.headerNavItem {
  $block: &;

  &__subNavWrapper {
    position: absolute;
    top: 100%;
    background-color: token("general-light");
    left: 0;
    right: 0;

    &.subnav_open {
      &#{$block}__subNavWrapper {
        min-height: calc(100vh - rem(110));
        min-height: calc(100dvh - rem(110));

        @include above("md") {
          overflow: auto;
        }
      }
    }

    &.closed {
      display: none;
    }

    &.open {
      display: block;
    }

  }

  &.mobile {
    @include below("md") {
      isolation: isolate;

      + #{$block}__subNavWrapper {
        top: 0;
        left: 100vw;
        z-index: 10;
        padding: rem(40) rem(24);

        &:empty {
          display: none;
        }
      }
    }
  }

  &.nav_open {
    @include below("md") {
      & + #{$block}__subNavWrapper {
        display: flex;
        flex-direction: column;
        width: calc(100vw);
        height: 100%;
      }
    }
  }
}

.subNav {
  $block: &;

  isolation: isolate;

  &__header {
    @include above("md") {
      background-color: token(general-accent);
      padding: rem(40) 0;
    }
  }

  &__headerInner {
    @include below("md") {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: rem(24);
      padding-bottom: rem(40);
    }

    @include above("md") {
      @include container;
      display: grid;
      grid-template-columns: 3fr 2fr;
      grid-template-rows: 1fr;
    }
  }

  &__headerLeft {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    @include above("md") {
      align-items: center;
      flex-direction: row;
    }
  }

  &__headerRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__headerTitle {
    @include typeHeadline(20);
    width: min(100%, rem(630));
    color: token("secondary-on-general-accent");

    @include above("mdlg") {
      font-size: rem(28);
    }
  }

  &__headerMobileTitle {
    @include typeHeadline(16, bold);
    margin-bottom: rem(8);
    width: min(100%, rem(630));
    color: token("interaction-on-general-light");

    @include above("mdlg") {
      font-size: rem(28);
    }
  }

  &__main {
    @include below("md") {
      display: flex;
      flex-direction: column;
    }
  }

  &__mainTop {
    @include above("md") {
      border-top: rem(1) solid token("general-divide-light");
      border-bottom: rem(1) solid token("general-divide-light");
    }
  }

  &__mainTopInner {
    gap: rem(24);
    display: flex;
    flex-direction: column;

    @include above("md") {
      @include container;
      flex-direction: row;
      position: relative;
      align-items: center;
    }
  }

  &__mainTopLeft {
    color: token("secondary-on-general-light");
  }

  &__mainTopRight {
    display: flex;
    flex-direction: column;
    gap: rem(24);

    @include above("md") {
      flex-direction: row;
      align-items: center;
      height: rem(71);
      gap: rem(32);
    }
  }
}