@import "/styles/tools/";

.promotedContentBlock {
  @include reset-button();
  margin-top: rem(40);
  padding: 0;
  display: flex;
  align-items: stretch;
  border: 0;
  border-radius: rem(4);
  overflow: hidden;
  white-space: normal;
  text-align: start;

  &__image {
    min-width: rem(80);
    height: auto;
    position: relative;
    object-fit: cover;
  }

  &__content {
    padding: rem(19) rem(24);
    background-color: token("general-accent");
    display: flex;
    flex-direction: column;
    gap: rem(8);
  }

  &__heading {
    color: token("secondary-on-general-light");
    font-weight: 600;
    font-size: rem(12);
    line-height: rem(12);
    letter-spacing: rem(-0.12);
  }

  &__subHeading {
    color: token("primary-on-general-light");
    font-weight: 400;
    font-size: rem(16);
    line-height: rem(24.32);
    letter-spacing: rem(-0.32);
  }
}
