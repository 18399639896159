@mixin reset-input($usePixelRatio: true) {
  display: block;
  vertical-align: baseline;
  width: 100%;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  font-weight: normal;
  font-size: inherit;
  line-height: normal;
  font-family: inherit;
  font-display: swap;
  letter-spacing: inherit;
  color: inherit;
  background-color: transparent;
  box-shadow: none;
  appearance: none;

  // Prevent iOS automatically zooming in
  @if ($usePixelRatio) {
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      font-size: 16px;
    }
  }


  &::placeholder {
    color: inherit;
  }
}

@mixin reset-select {
  display: block;
  width: 100%;
  border: 0;
  border-radius: 0;
  font-weight: normal;
  font-size: inherit;
  line-height: normal;
  font-family: inherit;
  font-display: swap;
  white-space: nowrap;
  letter-spacing: inherit;
  color: inherit;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  user-select: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  &:-moz-focusring {
    color: currentColor;
  }
}

@mixin reset-textarea {
  display: block;
  width: 100%;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  font-weight: normal;
  font-size: inherit;
  line-height: normal;
  font-family: inherit;
  font-display: swap;
  letter-spacing: inherit;
  color: inherit;
  background-color: transparent;
  box-shadow: none;
  appearance: none;
}

@mixin reset-button {
  display: inline-block;
  vertical-align: middle;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  font-weight: normal;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-display: swap;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  letter-spacing: inherit;
  color: inherit;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  user-select: none;
  appearance: none;

  &[disabled] {
    text-shadow: none;
    cursor: not-allowed;
    pointer-events: none;
  }
}
