@import "/styles/tools/";

.homepageHero {
  $block: &;

  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: token("general-dark");
  isolation: isolate;
  box-shadow: 0 0 0 100vmax token("general-dark");
  clip-path: inset(0 -100vmax);

  @include above("md") {
    position: relative;
    overflow: hidden;
    height: 80vh;
    min-height: rem(600);
    max-height: rem(900);

    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='1440' height='542' viewBox='0 0 1440 542' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0L1440 0V542L0 542L0 0Z' fill='url(%23paint0_linear_1326_2333)' fill-opacity='0.7'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1326_2333' x1='702' y1='90' x2='632' y2='448' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0667804' stop-color='%23070D19' stop-opacity='0'/%3E%3Cstop offset='0.766438' stop-color='%23070D19'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
      background-position: bottom left;
      background-size: cover;
      background-repeat: no-repeat;
      content: "";
    }
  }

  @include above("lg") {
    overflow: hidden;
  }

  &__content {
    @include container;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    z-index: 2;
    padding-top: rem(80);
    padding-bottom: rem(40);

    @include above(md) {
      height: 100%;
      padding-top: 0;
      padding-bottom: rem(64);
    }

    @include above(lg) {
      padding-bottom: rem(96);
    }
  }

  &__inner {
    max-width: 100%;

    @include above(sm) {
      max-width: 90%;
    }

    @include above(md) {
      max-width: rem(736);
    }

    &.no_media {
      @include below(md) {
        padding-bottom: rem(16);
      }
    }

  }

  &__title {
    @include typeDisplay(92);
    margin-top: 0;
    margin-bottom: rem(28);
    color: token("primary-on-general-dark");
    line-height: 100%;

    @include below(lg) {
      font-size: rem(72);
    }

    @include below(mdlg) {
      font-size: rem(60);
    }

    @include below("md") {
      font-size: rem(48);
      margin-bottom: rem(16);
      line-height: 105% !important;
    }
  }

  &__description {
    @include typeHeadline(20);
    color: color("marine-green-10");

    @include above("md") {
      font-size: rem(28);
    }

    b,
    strong {
      font-weight: 400;
      color: color("marine-green-digital");
    }

    p {
      margin-bottom: rem(16);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__videoButton {
    @include typeLabel(16, bold);
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: rem(32);
    height: rem(32);
    padding: rem(8);
    border: 0;
    background-color: rgba(token("general-dark"), 0.4);
    color: token("primary-on-general-dark");
    backdrop-filter: blur(12px);
    transition: 150ms ease-in-out;
    cursor: pointer;

    @include above("md") {
      right: -1px;
      bottom: -1px;
      width: 165px;
      height: 56px;
      padding: rem(16) rem(24) rem(16) rem(54);
      border-top: 1px solid token("general-dark");
      border-left: 1px solid token("general-dark");
    }

    &:hover {
      background-color: rgba(token("general-divide-dark"), 0.6);
      transition: 150ms ease-in-out;
    }

    &.pause {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5 6H15.5V18H13.5V6Z' fill='white'/%3E%3Cpath d='M8 6H10V18H8V6Z' fill='white'/%3E%3C/svg%3E");
      background-position: center;
      background-size: rem(20);
      background-repeat: no-repeat;

      @include above("md") {
        background-position: 24px center;
        background-size: auto;
      }
    }

    &.play {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 5V19L17.5 12L6.5 5Z' fill='white'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-size: rem(20);
      background-repeat: no-repeat;

      @include above("md") {
        background-position: 24px center;
        background-size: auto;
      }
    }
  }

  &__backgroundMedia {
    position: relative;
    width: 100%;
    height: auto;
    object-fit: cover;

    @include below(md) {
      @include aspect-ratio(144, 78);
    }

    @include above("md") {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__container {
    position: relative;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__imageWrapper {
    @include below(md) {
      @include aspect-ratio(144, 78);
      position: relative;
    }

    @include above('md') {
      position: absolute;
      inset: 0;
    }

    picture {
      position: absolute;
      inset: 0;
    }

  }

  &.video {
    @include above("lg") {
      &:before {
        background-size: cover;
      }
    }

    #{$block}__backgroundMedia {
      video {
        object-fit: cover !important;
      }
    }
  }

  &+[class^="ComponentInsightListing_insightCardListing__"] {
    margin-top: rem(0) !important;
    border-top: 0 !important;
    border-bottom: rem(1) solid token("general-divide-light");
  }

  &__clickthroughs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: rem(8);
    margin-top: rem(32);

    @include above("md") {
      flex-direction: row;
      margin-top: rem(40);
    }
  }
}