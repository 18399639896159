@import "/styles/tools/";

.preview_indicator {
    $block: &;
    position: fixed;
    top: 20%;
    right: 0;
    z-index: 1000;
    transform: translateY(-50%);

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(16);
        background: color("yellow");
        font-size: rem(16);
        font-weight: 600;        
        letter-spacing: rem(0.5);
        color: color("navy");
        border-bottom: 2px solid token("general-divide-dark");
        border-top-left-radius: rem(16);
        border-bottom-left-radius: rem(16);        
                
        &:hover {
            background-color: color("marine-green");
            color: color("white");
            border-bottom: 2px solid color("general-divide-dark");
        }
    }

}