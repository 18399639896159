@import "/styles/tools/";

.searchBox {
  &__item {
    display: flex;
    flex-direction: column;
    gap: rem(8);
    width: 100%;

    @include above("md") {
      max-width: rem(736);
      gap: rem(12);
    }

    label {
      @include typeLabel(16, bold);
      color: token("secondary-on-general-dark");
    }

    &__input {
      position: relative;
      display: grid;
      grid-template-columns: rem(24) auto;
      align-items: center;
      column-gap: rem(16);
      row-gap: rem(24);

      &::before {
        content: "";
        position: absolute;
        top: rem(48);
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(token("general-divide-dark"), 0.4);

        @include above("md") {
          bottom: 0;
          top: auto;
        }
      }
      
      @include above("md") {
        grid-template-columns: rem(24) auto max-content;
        padding-bottom: rem(20);
      }

      input {
        @include typeDisplay(30);
        position: relative;
        width: 100%;
        height: rem(48);
        border: none;
        outline: none;
        color: inherit;
        background-color: transparent;
        &::placeholder {
          color: token("secondary-on-general-dark");
        }
      }

      button {
        grid-column-start: 1;
        grid-column-end: 3;

        @include above("md") {
          grid-column-start: 3;
        }
      }

    }

    &__inputIcon {
      svg {
        width: rem(16);
        height: rem(16)
      }
      @include above("md") {
        svg {
          width: rem(24);
          height: rem(24)
        }
      }
    }
  }
}
