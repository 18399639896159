@import "styles/tools";

.carousel {
  $this: &;

  &__slider {
    display: block !important;
    transition: height 0.3s ease-in-out;
    
    &.visible {
      overflow: visible;
      .keen-slider__slide {
        visibility: visible;
      }
    }

    .keen-slider__slide {
      transition: opacity 0.3s ease-in-out;
      opacity: 1;
    }

    &.init {
      display: flex !important;
    }
  }

  &__liveregion {
    @include visibly-hidden;
  }

  &__controls {
    margin-top: rem(40);
  }

  &__controlList {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(8);
    caret-color: transparent;
    
    & + & {
      margin-top: rem(24);
    }
  }

  &__dot {
    display: block;
    width: rem(12);
    height: rem(12);
    padding: 0;
    margin: 0 rem(4);
    border: solid rem(1) token("outline-interaction-secondary");
    border-radius: 100%;

    &.active {
      background-color: token("interaction-primary");
      border-color: token("interaction-primary");
    }
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(40);
    height: rem(40);
    border-radius: 50%;
    border: solid rem(2) token("outline-interaction-secondary");
    color: token("primary-on-interaction-secondary");
    transition: border-color 0.3s ease-in-out;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      opacity: 0.5 !important;
    }
    
    &:hover,
    &:focus-visible {
      border-color: token("primary-on-interaction-secondary");
      color: token("primary-on-interaction-secondary");
    }

    &.prev {
      svg {
        position: relative;
        left: rem(-1);
        transform: rotate(180deg);
      }
    }
  }

  &__buttonText {
    @include visibly-hidden;
  }

  &__overlay {

    &:has([class*='homepageHero']) {
      background: token("general-dark");      
    }

    &_dots {
      @include container;
      position: absolute;
      bottom: rem(32);
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: rem(8);      

      @include below("md") {
        justify-content: center;
        bottom: rem(18);
      }

      
    }

    &_arrows{
      @include container;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      left:0;
      right:0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: rem(8);      
      
      @media screen and (min-width: 1760px){
        padding-left: 0 !important;
        padding-right: 0 !important;        
      }

      @include below("md") {
        top: auto;
        bottom: rem(-16);
      }

    }

    #{$this}__dot {
      transition: all 0.3s ease-in-out;
      background-color: rgba(255,255,255,.25);
      cursor: pointer;
      
      &:is(:hover, :focus, .active) {
        background-color: rgba(token("interaction-primary"),.5);        
      }

      &.active {
        background-color: token("interaction-primary");
      }      
    }

    #{$this}__button {
      opacity: 0;
      transition: all 0.3s ease-in-out;
      background-color: rgba(255,255,255,.25);
      cursor: pointer;

      &:is(:hover, :focus-visible) {
        background-color: rgba(white,1);        
        border-color: rgba(white,1);        
      }

      &.active {
        background-color: token("interaction-primary");
      }      
    }
    
    @include below("md") {
      #{$this}__button {
        opacity: 1;
      }
    }          

    @include above("md") {
      &:is(:hover) {
        #{$this}__button {
          opacity: 1;        
        }      
      }      
    }

    @include below("md") {      
      [class*="__container"],
      [class*="__backgroundMedia"] {
          height: auto !important;
      }
    }
  
  }

}
