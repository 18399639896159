@import "/styles/tools/";

.searchContainer {
  z-index: 1;
  width: 100%;
  height: auto;
  padding: rem(24) rem(16);
  background-color: token("primary-on-general-dark");
  @include above("md") {
    padding: 0 rem(40);
  }
  
  @include above("lg") {
    padding: 0 rem(80);
  }

  &__summary {
    @include typeHeadline(20);
    padding: rem(40) 0;
    color: token("secondary-on-general-light");

    &__query {
      @include typeHeadline(20, bold);
    }
  }

  &__noResults {
    display: flex;
    flex-direction: column;
    gap: rem(24);
    padding-bottom: rem(40);

    &__message {
      @include typeHeadline(20, bold);
    }

    &__links {
      @include typeHeadline(16, bold);
      display: flex;
      flex-direction: column;
      row-gap: rem(16);
      color: token('interaction-on-general-light');

      &__heading {
        @include typeLabel(16);
        color: token('secondary-on-general-dark');
      }
    }
  }
}
