@import "/styles/tools/";

.searchItem {
  $this: &;

  display: grid;
  grid-template-rows: auto;
  row-gap: rem(16);
  padding: rem(24) 0;
  border-top: rem(1) solid token("general-divide-light");
  cursor: pointer;

  @include above("md") {
    grid-template-columns: 1fr 2fr 1fr;
    padding: rem(24) 0 rem(40) 0;
    gap: rem(12);
  }

  &__tags {
    @include typeLabel(14, "bold");
    display: flex;
    align-items: flex-start;
    gap: rem(8);
    margin-top: rem(8);
    color: token("primary-on-general-light");

    &__topic {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      color: token("secondary-on-general-light");
      column-gap: rem(4);
    }

    &__topicItem {
      &:after {
        content: ",";
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  &__second {
    @include typeBody(16);
    color: token("secondary-on-general-light");

    @include above("md") {
      display: flex;
      flex-direction: column;
      gap: rem(16);
    }

    &__date {
      @include typeLabel(14, bold);

      @include below("md") {
        margin: rem(8) 0 rem(16) 0;
      }
    }

    &__heading {
      @include typeHeadline(20);
      color: token("primary-on-general-light");

      @include above("md") {
        @include typeHeadline(24);
      }

      #{$this}:hover & {
        text-decoration: underline;
        text-decoration-color: token("interaction-on-general-dark");
      }
    }
  }

  &__last {
    display: flex;
    align-items: flex-start;

    @include above("md") {
      margin-top: 0;
      justify-content: flex-end;
    }
  }
}
