@import "/styles/tools/";
@import "/styles/ui/";

.btn {
  $block: &;
  @extend %button;

  &__solid {
    @extend %button-primary;
  }

  &__solid-white {
    background-color: token("primary-on-general-dark");
    color: token("primary-on-interaction-primary");
  }

  &__outline {
    @extend %button-secondary;
  }

  &__underline {
    @extend %button-tertiary;
  }

  &__external {
    @extend %button-tertiary;
    @include typeHeadline(20);
    margin-bottom: rem(16);
    padding: 0;
    border-bottom: 0;
    color: token("primary-on-general-light") !important;
    text-decoration: none !important;

    @include above(md) {
      @include typeHeadline(24);
    }

    span {
      position: relative;
      border-bottom: solid rem(2) token("outline-interaction-secondary");
      padding-bottom: rem(6);

      &:empty {
        display: none;
      }
    }

    svg {
      transform: translateY(-3px);
    }
  }

  &__mobile_nav {
    @include typeHeadline(24);
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    border-radius: 0 !important;
    background: none;
    color: token("primary-on-interaction-primary");
    border: 0;
    border-bottom: rem(4) solid;
    border-bottom-color: token("underline-interaction-text-link");
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;

    &:hover,
    &:focus-visible {
      border-bottom-color: token("interaction-primary");
    }

    &:active {
      background-color: transparent;
    }
  }

  &__header_underline {
    @include typeLabel(14, bold);
    position: relative;
    display: inline-flex;
    width: fit-content;
    border-radius: 0 !important;
    background: none;
    color: token("secondary-on-general-light");
    border: 0;
    padding: 0;
    gap: 0;

    @include above("mdlg") {
      font-size: rem(15);
    }

    @include above("lg") {
      font-size: rem(16);
    }

    &:before {
      position: absolute;
      display: flex;
      content: "";
      height: rem(4);
      background-color: transparent;
      bottom: rem(-25);
      left: 0;
      right: 0;
    }

    &:hover,
    &:focus-visible {
      color: token("primary-on-general-light");

      &:before {
        background-color: token("secondary-on-general-light");
      }
    }

    &.active,
    &:active {
      color: token("primary-on-general-light");
      background-color: transparent !important;

      &:before {
        background-color: token("interaction-primary");
      }
    }
  }

  &__small {
    @include typeLabel(14, bold);
    padding: rem(8) rem(10) rem(8) rem(13);
    gap: rem(4);
  }

  &__medium {
    @include typeLabel(16, bold);
    padding: rem(8) rem(16);
    gap: rem(8);
  }

  &__icon {
    isolation: isolate;

    svg {
      display: inline-block;
    }
  }

  &__icon_left {
    flex-direction: row-reverse;
  }

  &__no-icon {
    &#{$block}__solid {

      &:hover,
      &:focus-visible {
        text-decoration: underline;
        background-color: transparentize(token("interaction-primary"), 0.4);
      }
    }

    &#{$block}__solid-white {

      &:hover,
      &:focus-visible {
        text-decoration: underline;
        background-color: transparentize(token("primary-on-general-dark"), 0.4);
      }
    }
  }

  &__disabled {
    background-color: rgba(color("error"), 0.2);
  }

  &.arrow {
    gap: rem(28);

    &#{$block}__icon_left {
      gap: rem(16);
      padding-left: rem(28);

      svg {
        transform: translateX(0) scale(-1);
        transition: all 0.4s ease-in-out;
      }

      &:hover,
      &:focus-visible {
        svg {
          transform: translateX(rem(-12)) scale(-1);
          transition: all 0.4s ease-in-out;
        }
      }
    }

    &#{$block}__icon_right {
      padding-right: rem(28);

      svg {
        transform: translateX(0);
        transition: all 0.4s ease-in-out;
      }

      &:hover,
      &:focus,
      &:focus-within {
        svg {
          transform: translateX(rem(12));
          transition: all 0.4s ease-in-out;
        }
      }
    }

    &#{$block}__underline {
      padding: rem(9) 0 rem(9) 0;

      &#{$block}__icon_left {
        svg {
          transform: translateX(rem(3)) scale(-1);
          transition: all 0.4s ease-in-out;
        }

        &:hover,
        &:focus-visible {
          svg {
            transform: translateX(rem(-3)) scale(-1);
            transition: all 0.4s ease-in-out;
          }
        }
      }

      &#{$block}__icon_right {
        svg {
          transform: translateX(rem(-3));
          transition: all 0.4s ease-in-out;
        }

        &:hover,
        &:focus-visible {
          svg {
            transform: translateX(rem(3));
            transition: all 0.4s ease-in-out;
          }
        }
      }
    }

    &#{$block}__mobile_nav {
      padding-right: 0;
    }
  }

  &.menu {
    &#{$block}__solid {
      @include typeLabel(16, bold);
      display: inline-flex;
      align-items: center;
      gap: rem(8);
      height: rem(44);
      padding-left: rem(21);
      padding-right: rem(21);
      color: token("primary-on-general-dark");
      background-color: token("general-dark");
      outline: 0;
      box-shadow: 0 0 0 rem(0.5) rgba(token("primary-on-general-dark"), 0.5) inset,
        0 rem(4) rem(34) rgba(token("general-dark"), 0.16);
    }
  }

  &.menu-close {
    &#{$block}__solid {
      @include typeLabel(16, bold);
      display: inline-flex;
      align-items: center;
      justify-content: centers;
      gap: rem(8);
      height: rem(44);
      padding-left: rem(21);
      padding-right: rem(21);
      color: token("primary-on-general-feature");
      background-color: token("general-feature");
      outline: 0;
      box-shadow: 0 0 0 rem(0.5) rgba(token("primary-on-general-dark"), 0.5) inset,
        0 rem(4) rem(34) rgba(token("general-dark"), 0.16);
    }
  }

  &.chevron {
    &#{$block}__icon_left {
      svg {
        transform: translateX(0);
        transition: transform 0.4s ease-in-out;
      }

      &:hover,
      &:focus-visible {
        svg {
          transform: translateX(rem(-3));
          transition: transform 0.4s ease-in-out;
        }
      }
    }

    &#{$block}__icon_right {
      svg {
        transform: translateX(0);
        transition: transform 0.4s ease-in-out;
      }

      &:hover,
      &:focus-visible {
        svg {
          transform: translateX(rem(6));
          transition: transform 0.4s ease-in-out;
        }
      }
    }
  }

  &.chevron_down {
    gap: rem(6);

    svg {
      width: rem(8);
      height: rem(8);
    }

    &:active,
    &:hover,
    &:focus-visible {
      color: token("primary-on-general-light");
    }

    &#{$block}__icon_left {

      &.active,
      &:active {
        svg {
          transform: rotate(0);
        }
      }
    }

    &#{$block}__icon_right {

      &.active,
      &:active {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &.subNav_text {
    &#{$block}__mobile_nav {
      @include typeLabel(16, bold);
      font-size: rem(16);
    }
  }

  &.search {
    &#{$block}__icon_left {
      gap: rem(10);
    }

    &#{$block}__icon_right {
      gap: rem(10);
    }
  }

  &.dark,
  &.dark-blue,
  &.primaryDarkGreen {
    &#{$block}__outline {
      color: token("primary-on-general-dark");
      outline: 0;
      box-shadow: 0 0 0 rem(1) token("secondary-on-general-dark") inset;

      &:hover {
        outline: 0;
        text-decoration: underline;
        box-shadow: 0 0 0 rem(2) token("interaction-primary") inset;
      }
    }

    &#{$block}__underline {
      color: token("primary-on-general-dark");
    }
  }

  &.header__contact {
    &#{$block}__solid {
      background-color: token("general-accent");
      color: token("secondary-on-general-accent");
      outline: 0;
      box-shadow: 0 0 0 rem(1) token("general-accent") inset;

      &:hover {
        background-color: token("secondary-on-general-light");
        color: token("primary-on-general-feature");
      }

      &:active {
        border-color: transparent;
        background-color: rgba(token("general-accent"), 0.5);
      }

      &:focus-visible {
        outline: 0 !important;
        box-shadow: 0 0 0 rem(3) color("legacy-blue") inset !important;
      }
    }
  }

  &.header__search {
    &#{$block}__solid {
      gap: rem(10);
      background-color: token("general-accent");
      color: token("secondary-on-general-accent");
      outline: 0;
      box-shadow: 0 0 0 rem(1) token("general-accent") inset;

      &:active {
        border-color: transparent;
        background-color: rgba(token("general-accent"), 0.5);
      }

      &:focus-visible {
        outline: 0 !important;
        box-shadow: 0 0 0 rem(3) color("legacy-blue") inset !important;
      }
    }

    &#{$block}__underline {
      @include typeLabel(14, bold);
      color: token("secondary-on-general-light");
      padding-left: 0;
      padding-right: 0;
      border-bottom: rem(4) solid transparent;
      border-bottom-color: transparent !important;

      @include above("lg") {
        font-size: rem(16);
      }

      &:hover,
      &:focus-visible {
        color: token("primary-on-general-light");
        border-bottom-color: token("secondary-on-general-light") !important;
      }

      &:active {
        color: token("primary-on-general-light");
        background-color: transparent;
        border-bottom-color: token("interaction-primary") !important;
      }
    }
  }

  &.header__subSubNav {
    &#{$block}__underline {
      @include typeLabel(14, bold);
      gap: rem(8);
      border-bottom: rem(1) solid;
      border-bottom-color: token("primary-on-general-light");

      svg {
        width: rem(12);
        height: rem(12);
      }

      &:hover,
      &:focus-visible {
        border-bottom-color: token("interaction-primary");
      }

      &#{$block}__icon_left {
        svg {
          transform: translateX(0) scale(-1);
        }

        &:hover,
        &:focus-visible {
          svg {
            transform: translateX(rem(-3)) scale(-1);
          }
        }
      }

      &#{$block}__icon_right {
        svg {
          transform: translateX(0);
        }

        &:hover,
        &:focus-visible {
          svg {
            transform: translateX(rem(3));
          }
        }
      }
    }
  }

  &.filtertag {
    @media (min-width: rem(945)) {
      font-size: rem(16);
      color: token("primary-on-general-light");
    }

    &.active,
    &:active,
    &:hover,
    &:focus-visible {
      background-color: token("general-feature-2");
      color: token("primary-on-general-light");
      text-decoration: none;
    }
  }

  &.filter_clear,
  &.applied_filter {
    &#{$block}__solid {
      @include typeLabel(14);
      display: inline-flex;
      gap: rem(12);
      outline: 0;

      &#{$block}__icon_left {
        padding-left: rem(12);
        padding-right: rem(16);
      }

      &#{$block}__icon_right {
        padding-left: rem(16);
        padding-right: rem(12);
      }
    }
  }

  &.filter_clear {
    &#{$block}__solid {
      color: token("primary-on-general-accent");
      background-color: token("general-accent");
      box-shadow: 0 0 0 rem(0.5) rgba(token("general-divide-light"), 1) inset;
    }
  }

  &.applied_filter {
    &#{$block}__solid {
      color: token("primary-on-general-feature");
      background-color: token("general-feature");
      box-shadow: 0 0 0 rem(0.5) rgba(token("primary-on-general-dark"), 1) inset;
    }
  }

  &.mobile_button {

    &#{$block}__solid,
    &#{$block}__outline {
      width: min(100%, rem(205));
      justify-content: space-between;
      margin-bottom: rem(16);

      span {
        width: 100%;
      }
    }
  }

  &.no_border {
    &#{$block}__underline {
      border: 0;
      padding: 0;

      &:before {
        display: none;
      }
    }
  }

  &.searchDisabled {
    opacity: 0.5;
    pointer-events: none;
    filter: grayscale(1);
  }

  &.mobile-back {
    &.arrow {
      gap: rem(12);
      padding: rem(12) rem(18);

      &#{$block}__icon_left {

        &:hover,
        &:focus-visible {
          svg {
            transform: translateX(rem(-6)) scale(-1);
          }
        }
      }

      &#{$block}__icon_right {

        &:hover,
        &:focus-visible {
          svg {
            transform: translateX(rem(6));
          }
        }
      }
    }
  }
}