@import "/styles/tools/";
@import "/styles/ui/";

.header {
  position: sticky;
  top: 0;
  z-index: map-get($z-index, "header");
  background-color: token("general-light");

  &__container {
    @include container;
    padding-left: rem(24);
    padding-right: rem(24);
    display: flex;
    gap: rem(24);

    @include below("md") {
      justify-content: space-between;
    }

    @include above("lg") {
      gap: rem(40);
    }
  }

  &__grid {
    display: grid;
    gap: 1rem;

    @include above("md") {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__column {
    background-color: token("general-integrate");
  }

  &__main {
    border-bottom: rem(1) solid token("general-divide-light");
  }

  &__logo {
    @include aspect-ratio(155, 40);
    width: min(100%, rem(120));
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: rem(16);
    margin-bottom: rem(16);

    @include above("lg") {
      width: min(100%, rem(155));
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    flex: 1 1 auto;

    @include above("md") {
      gap: rem(32);
    }
  }

  &__contact {
    display: none;

    // Manual media query as is specific use case, client set additional links 
    // to header which breaks the design, so cta is now hidden at point of break
    // cta is hidden to that point, so navigation looks ok.
    @media screen and (min-width: 965px){
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__search {
    $block: &;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;

    @include above("md") {
      margin-right: rem(-24);
      width: rem(114);
      border-left: rem(1) solid token("general-divide-light");
    }

    @include above("lg") {
      width: rem(190);
    }

    &__subNavWrapper {
      position: absolute;
      top: 100%;
      background-color: token("general-dark");
      left: 0;
      right: 0;

      min-height: calc(100vh - rem(110));
      min-height: calc(100dvh - rem(110));

      @include above("md") {
        overflow: auto;
      }
    }
  }

  &__searchLink {
    @include typeLabel(16, bold);
    display: inline-flex;
    align-items: center;
    color: token("secondary-on-general-light");
    gap: rem(10);

    &:after {
      display: block;
      width: rem(16);
      height: rem(16);
      background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.33334 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33334C12.6667 4.38782 10.2789 2 7.33334 2C4.38782 2 2 4.38782 2 7.33334C2 10.2789 4.38782 12.6667 7.33334 12.6667Z' stroke='%23535A74' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13.9981 14L11.0981 11.1' stroke='%23535A74' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-size: rem(16);
      content: "";
    }
  }
}

.mobileMenuButtonWrapper {
  position: fixed;
  bottom: rem(24);
  z-index: map-get($z-index, "header");
  display: flex;
  justify-content: center;
  width: 100%;
}
