@import "styles/tools";

.testimonialCarousel {
  $this: &;
  @include container;
  @include vertical-spacing;

  @include above(md) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: rem(24);
  }

  &__inner {
    grid-column: 3 / 11;

    &:not(&.loaded) {

      // hide until carousel scaffolded to stop content jump
      #{$this}__slideWrapper:not(:first-child) {
        display: none;
      }
    }
  }

  &__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: auto;
  }

  &__quote {
    @include typeHeadline(20);
    color: token("secondary-on-general-light");
    text-align: center;
    quotes: "“""”";

    &::before {
      content: open-quote;
    }

    &::after {
      content: close-quote;
    }

    @include above(md) {
      @include typeHeadline(28);
    }
  }

  &__caption {
    display: flex;
    align-items: center;
    margin-top: rem(24);
  }

  &__imageWrapper {
    position: relative;
    width: rem(62);
    height: rem(62);
    margin-right: rem(12);
  }

  &__image {
    border-radius: 50%;
  }

  &__name {
    @include typeLabel(16, "bold");
    color: token("primary-on-general-light");
  }

  &__subname {
    @include typeLabel(16);
    color: token("secondary-on-general-light");
  }
}