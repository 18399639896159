@import "/styles/tools/";

.richTextWrapper {
  $this: &;

  &.standalone {
    @include vertical-spacing;
    @include container;

    @include above(md) {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      gap: rem(24);
    }
  }

  &__inner {
    #{$this}.standalone & {
      @include above(md) {
        grid-column: span 8;
      }
    }
  }
}