@import "/styles/tools/";

.relatedInsight {
  $block: &;

  display: block;
  background-color: token("general-feature");
  color: token("primary-on-general-feature");
  border-radius: rem(4);

  &+& {
    margin-top: rem(32);
  }

  &__imageWrapper {
    @include aspect-ratio(704, 469);
    position: relative;

    picture {
      position: absolute;
      inset: 0;
    }

    img {
      object-fit: cover;
    }

  }

  &__image {
    max-width: 100%;
    height: auto;
    border-radius: rem(4) rem(4) 0 0;
  }

  &__content {
    padding: rem(20);
  }

  &__tags {
    @include typeLabel(14, "bold");
    margin-bottom: rem(12);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: rem(8);
  }

  &__tag {
    color: token("secondary-on-general-feature");
  }

  &__heading {
    @include typeHeadline(16);
  }

  &__fauxBtn {
    @extend %button;
    @extend %button-primary;
    @include typeLabel(14, bold);
    margin-top: rem(10);
    padding: rem(8) rem(18) rem(8) rem(13);
    gap: rem(4);
    isolation: isolate;

    svg {
      display: inline-block;
      transform: translateX(0);
      transition: all 0.4s ease-in-out;
    }
  }

  &:hover,
  &:focus-visible,
  &:focus-within {
    .relatedInsight__fauxBtn {
      svg {
        transform: translateX(rem(6));
        transition: all 0.4s ease-in-out;
      }
    }
  }
}

.draft {
  position: relative;
  opacity: 0.7;
  cursor: not-allowed;

  a {
    pointer-events: none;
  }

  &:hover {
    &:after {
      content: "Linked page unpublished";
      text-align: center;
      background-color: rgba(255, 255, 255, 1);
      display: block;
      position: absolute;
      color: red;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid red;
      padding: 5px;
      text-wrap: pretty;
      font-size: 14px;
    }
  }
}