@import "/styles/tools/";
@import "/styles/ui/";

.customerLogin {
  &__link {
    @include typeLabel(12, bold);
    display: inline-flex;
    align-items: center;
    color: token("primary-on-general-feature");
    gap: rem(10);
    margin-top: rem(16);

    &:after {
      display: block;
      width: rem(12);
      height: rem(12);
      background-image: url("data:image/svg+xml,%0A%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.99996 0.666626C4.52663 0.666626 3.33329 1.85996 3.33329 3.33329C3.33329 4.80663 4.52663 5.99996 5.99996 5.99996C7.47329 5.99996 8.66663 4.80663 8.66663 3.33329C8.66663 1.85996 7.47329 0.666626 5.99996 0.666626ZM7.33329 3.33329C7.33329 2.59996 6.73329 1.99996 5.99996 1.99996C5.26663 1.99996 4.66663 2.59996 4.66663 3.33329C4.66663 4.06663 5.26663 4.66663 5.99996 4.66663C6.73329 4.66663 7.33329 4.06663 7.33329 3.33329ZM9.99996 9.33329C9.86663 8.85996 7.79996 7.99996 5.99996 7.99996C4.19996 7.99996 2.13329 8.85996 1.99996 9.33996V9.99996H9.99996V9.33329ZM0.666626 9.33329C0.666626 7.55996 4.21996 6.66663 5.99996 6.66663C7.77996 6.66663 11.3333 7.55996 11.3333 9.33329V11.3333H0.666626V9.33329Z' fill='%23F1F6FC'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-size: rem(12);
      content: "";
    }

    @include above("md") {
      margin-top: 0;
    }
  }
}
