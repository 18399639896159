@import "/styles/tools/";

.authorContent {
  $block: &;

  display: flex;
  flex-direction: row;
  gap: rem(12);

  @include above("md") {
    gap: rem(16);
  }

  &__authorImage {
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    min-width: rem(48);
    height: rem(48);
    
    picture{
      position: absolute;
      inset: 0;
    }
    
    img {
      object-fit: cover;
    }
  }

  &__authorInfo {
    display: flex;
    flex-direction: column;
  }

  &__authorName {
    @include typeLabel(16, bold);
    color: token("primary-on-general-accent");
  }

  &__authorJobTitle {
    @include typeLabel(16);
    color: token("secondary-on-general-accent");

    @media (max-width: 350px) {
      @include typeLabel(14);
    }
  }

  &__authorBio {
    width: min(100%, rem(700));
  }
}
