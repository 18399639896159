// Aspect Ratio mixin to include support for browsers that don't
// support aspect-ratio property

@mixin aspect-ratio($w, $h) {
    aspect-ratio: calc($w / $h);

    @supports not (aspect-ratio: calc($w / $h)) {
        &:before {
            float: left;
            padding-top: calc((($h / $w) * 100) * 1%);
            content: '';
        }
        &:after {
            display: block;
            content: '';
            clear: both;
        }
    }
}
