@import "/styles/tools/";

.fiftyFiftyCallout {
  $this: &;

  &__inner {
    @include container($withPadding: false);
  }

  &__imageColumn {
    flex-direction: column;

    @include below("md") {
      padding-left: rem(16);
      padding-right: rem(16);
      padding-top: rem(16);
    }
  }

  &__imageWrapper {
    @include aspect-ratio(3, 2);
    display: block;
    position: relative;
    width: 100%;
    max-width: 100%;
    grid-row: 1;
    overflow: hidden;

    @include below(md) {
      border-radius: rem(4);
    }

    picture {
      position: absolute;
      inset: 0;
    }

    img,
    video {
      object-fit: cover;
    }

    &.playing {
      #{$this}__playButton {
        opacity: 0;
        transition: opacity 0.5s ease;
      }

      &:hover {
        #{$this}__playButton {
          opacity: .8;
          transition: opacity 0.5s ease;
        }
      }

      &.sound {
        #{$this}__playButton {
          display: none;
        }
      }
    }

    &.video {
      border-radius: 4px;
    }
  }

  &__caption {
    @include typeLabel(12);
    position: relative;
    padding-left: rem(12);
    margin-top: rem(10);
    margin-bottom: rem(10);
    border-left-width: rem(2);
    border-left-style: solid;
    border-left-color: token("general-divide-feature-2");
    color: token("secondary-on-general-light");

    @include above("mdlg") {
      @include typeLabel(16);
      margin-top: rem(8);
      margin-bottom: rem(8);
    }

    &.lightPurple,
    &.secondaryPurple {
      border-left-color: token("general-manage");
    }

    &.lightYellow,
    &.secondaryYellow {
      border-left-color: token("general-perform");
    }

    &.lightPink,
    &.secondaryPink {
      border-left-color: token("general-integrate");
    }

    &.lightOrange,
    &.secondaryOrange {
      border-left-color: token("general-tbc");
    }

    &.lightGreen,
    &.secondaryGreen {
      border-left-color: token("general-comply");
    }

    &.branded {
      @include below(md) {
        top: rem(-20);
        margin-bottom: rem(-4);
      }
    }
  }

  &__image,
  &__video {
    overflow: clip;
    object-fit: cover;
    border-radius: rem(4) 0 0 rem(4);

    @include above(md) {
      #{$this}.branded.left & {
        border-radius: 0 rem(4) rem(4) 0;
      }

      #{$this}.branded.right & {
        border-radius: rem(4) 0 0 rem(4);
      }

      #{$this}.standard & {
        border-radius: rem(4);
      }
    }

    &.gatedNoVideo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgba(map-get($colors, "error"), .15);
      box-shadow: inset 0 0 0 rem(2) rgba(map-get($colors, "error"), .5);
      display: flex;
      justify-content: center;
      align-items: center;
      color: map-get($colors, "error");
      text-align: center;
      line-height: 1.4;
      font-style: italic;
    }
  }

  &__content {
    position: relative;
    margin-top: rem(-4);
    padding: rem(24) rem(16) rem(40) rem(16);
    border-radius: rem(4);
    grid-row: 1;
    z-index: 1;

    @include above(md) {
      display: flex;
      align-items: flex-start;
      align-self: flex-end;
      flex-direction: column;
      margin-top: 0;
      padding: 0;
      border-radius: 0;
    }

    #{$this}.standard & {
      background-color: token("general-light");
    }

    #{$this}.branded & {
      background-color: token("general-accent");

      @include above(md) {
        @include container;
        @include vertical-spacing;
        margin: 0;
        width: 50%;
      }
    }

    @include above(md) {

      &.align_top {
        align-self: flex-start;
      }

      &.align_middle {
        align-self: center;
      }

      &.align_bottom {
        align-self: flex-end;
      }

    }
  }

  &__contentInner {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    min-height: rem(140);
    overflow: hidden;

    &.expanded {
      max-height: rem(100vh);
      overflow-x: clip;
      overflow-y: auto;
    }

    .standard &,
    .branded & {
      &.collapsed {
        &:after {
          display: block;
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: rem(18);
        }
      }
    }

    .standard & {
      &.collapsed {
        &:after {
          background: linear-gradient(rgba(255, 255, 255, 0), token("general-light"));
        }
      }
    }

    .branded & {
      &.collapsed {
        &:after {
          background: linear-gradient(rgba(255, 255, 255, 0), token("general-accent"));
        }
      }
    }
  }

  &__heading {
    color: token("primary-on-general-light");

    .standard & {
      @include typeHeadline(24);

      @include above(mdlg) {
        @include typeHeadline(32);
      }
    }

    .branded & {
      @include typeDisplay(40, 400);

      @include above(mdlg) {
        font-size: rem(40);
      }
    }

    &:empty {
      &+#{$this}__text {
        margin-top: 0;
      }
    }
  }

  &__text {
    @include rte(true, true);
    position: relative;

    color: token("secondary-on-general-light");


    @include above(md) {
      margin-top: rem(24);
    }

    @include above(mdlg) {
      font-size: rem(20);
    }

  }

  &__readMore {
    color: token("interaction-on-general-light");
    margin-top: rem(30);
    font-size: rem(18);
    padding: 0;
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    border: 0;

    &:hover {
      color: token('secondary-on-general-dark');
    }
  }

  &__cta {
    margin-top: rem(24);
  }

  &__playButton {
    @include reset-button;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: rem(64);
    height: rem(64);
    border-radius: 0 rem(8) 0 0;
    background-color: transparentize(token("interaction-on-general-dark"), 0.3);
    z-index: 1;
    transition: ease 0.2s;

    &:hover {
      background-color: token("interaction-on-general-dark");
    }

    @include above(md) {
      width: rem(96);
      height: rem(96);
    }
  }

  &__playIcon {
    width: rem(24);
    color: token("general-dark");

    @include above(md) {
      width: rem(40);
    }
  }

  &__spacing {
    padding-bottom: rem(40) !important;
  }

  &.standard {
    background-color: token("general-light");

    #{$this}__inner {      
      @include above(md) {
        @include container;
        @include vertical-spacing;
  
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-gap: rem(24);
  
        &.left {
          #{$this}__imageColumn {
            grid-column: span 6;
          }
  
          #{$this}__content {
            grid-column: 8 / span 5;
          }
        }
  
        &.right {
          #{$this}__content {
            grid-column: span 5;
          }
  
          #{$this}__imageColumn {
            grid-column: 7 / span 6;
          }
        }
      }
    }

  }

  &.branded {
    background-color: token("general-accent");

    
    #{$this}__inner {          
      @include above(md) {
        display: flex;
        flex-flow: row;
        
        &.right {
          flex-flow: row-reverse;
        }
  
        #{$this}__content {
          margin: 0;
          width: 50%;
        }
  
        #{$this}__imageColumn {
          display: flex;
          width: 50%;
          height: auto;
  
        }
      }
  
      @include below(md) {
        #{$this}__imageColumn {
          padding-left: rem(0);
          padding-right: rem(0);
          padding-top: rem(0);
  
          #{$this}__caption {
            display: none;
          }
  
        }
  
        #{$this}__imageWrapper {
          border-radius: 0 !important;
          &:after {
            bottom: 20px;
          }
        }
  
        #{$this}__image {
          border-radius: 0 !important;
        }
  
        #{$this}__content {
          border-radius: rem(6);
          top: rem(-6);
        }
  
      }
    }
  }
}