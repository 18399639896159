@import "/styles/tools/";

.relatedInsightWrapper {
  &:not(:first-child) {
    margin-top: rem(32);
  }

  &__title {
    @include typeHeadline(20);
    margin-bottom: rem(24);
    color: token("primary-on-general-light");
  }
}