@mixin container($withPadding: true) {
  width: auto;
  margin-left: auto;
  margin-right: auto;

  @each $breakpoint,
  $map in $container {
    $padding: container-value($breakpoint, 'padding');

    $max-width: container-value($breakpoint, 'max-width');

    @if $max-width  !='none' {
      @if type-of($max-width) !=number or unit($max-width) !=px {
        @error "$container > #{$breakpoint} > 'max-width' is not a length in px";
      }
    }

    @include only($breakpoint) {
      max-width: $max-width;

      @if ($withPadding) {
        padding-left: rem($padding);
        padding-right: rem($padding);
      }
    }
  }
}